const downloadFile = (url: string, fileName = ''): void => {
  const link = document.createElement('a');

  link.href = url;

  link.download = fileName;

  document.body.appendChild(link);

  link.click();

  link.parentNode.removeChild(link);
};

export default downloadFile;
