import { forwardRef, ReactElement, ForwardedRef } from 'react';
import { useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';

import validate from './form/validate';
import Input from '~/ui/components/inputs/Input';
import Select from '~/ui/components/inputs/Select';
import ImageUpload from '~/ui/components/inputs/ImageUpload';

import { timezoneOptions } from '~/ui/constants/timezones';
import { IProgram } from '~/services/api/programs/types';
import styles from './MainForm.module.scss';

interface IProps {
  initialValues: IProgram;
  onSubmit: (values: IProgram) => void;
}

const MainForm = forwardRef(
  (
    { initialValues, onSubmit: onSubmitCallback }: IProps,
    ref: ForwardedRef<HTMLFormElement>,
  ): ReactElement => {
    const {
      register,
      control,
      handleSubmit,
      formState: { errors },
    } = useForm<IProgram>({
      resolver: validate,
      defaultValues: initialValues,
    });

    const onSubmit = async (values: IProgram) => {
      onSubmitCallback(values);
    };

    return (
      <div className={styles.addProgram}>
        <div className={styles.title}>Add Program</div>
        <form ref={ref} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={6}>
              <div className={styles.inputRow}>
                <Input name="name" register={register} errors={errors} label="Program Name *" />
              </div>
              <div className={styles.inputRow}>
                <Select
                  name="timezone"
                  control={control}
                  errors={errors}
                  options={timezoneOptions}
                  label="Timezone *"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <Grid container justifyContent="center">
                <div className={styles.inputRow}>
                  <ImageUpload
                    name="imageStorageFileId"
                    control={control}
                    errors={errors}
                    dropzoneText="+ Attach Icon"
                    initialSrc={initialValues.imageStorageFileUrl}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  },
);

export default MainForm;
