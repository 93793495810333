import onGetStorageFiles from './onGetStorageFiles';
import onGetMoreStorageFiles from './onGetMoreStorageFiles';
import onCreateStorageFile from './onCreateStorageFile';
import onCreateStorageFilesByPatientId from './onCreateStorageFilesByPatientId';
import onDeleteStorageFile from './onDeleteStorageFile';

export default {
  onGetStorageFiles,
  onGetMoreStorageFiles,
  onCreateStorageFile,
  onCreateStorageFilesByPatientId,
  onDeleteStorageFile,
};
