import { ReactElement } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import MUITable from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import InfiniteScroll from 'react-infinite-scroll-component';
import { IPagination } from '~/utils/pagination';
import Loader from '~/ui/components/common/Loader';
import NoDataFound from '~/ui/components/reusable/NoDataFound';
import { IStatusMobileUser } from '~/services/api/mobileUsers/types';
import styles from './Table.module.scss';
import Item from './Item';

interface IProps {
  mobileUsers: IStatusMobileUser[];
  onLoadMore: () => void;
  pagination: IPagination;
}

const Table = ({ mobileUsers, onLoadMore, pagination }: IProps): ReactElement => (
  <TableContainer className={styles.tableContainer} id="scrollableDiv">
    <InfiniteScroll
      next={onLoadMore}
      hasMore={pagination.hasMore}
      loader={<Loader />}
      dataLength={mobileUsers.length}
      scrollableTarget="scrollableDiv"
    >
      <MUITable>
        <TableHead>
          <TableRow>
            <TableCell>Mobile Number</TableCell>
            <TableCell>User Type</TableCell>
            <TableCell>Program</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mobileUsers.length > 0 ? (
            mobileUsers.map(mobileUser => <Item key={mobileUser.id} mobileUser={mobileUser} />)
          ) : (
            <TableRow>
              <TableCell colSpan={3}>
                <NoDataFound />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </MUITable>
    </InfiniteScroll>
  </TableContainer>
);

export default Table;
