import { ReactElement, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import 'react-phone-input-2/lib/material.css';

import { useStoreActions } from '~/store/hooks';
import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';
import NotFound from './components/layouts/NotFoundPage';
import SignIn from './pages/SignIn';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import Programs from './pages/Programs';
import Profile from './pages/Profile';
import Users from './pages/Users';
import Patients from './pages/Patients';
import Inventory from './pages/Inventory';
import ActivityLogs from './pages/ActivityLogs/ActivityLogsList';
import PatientActivityLogs from './pages/ActivityLogs/PatientActivityLogs';
import PatientDashboard from './pages/Patient/Dashboard';
import PatientDevices from './pages/Patient/Devices';
import PatientNotificationRules from './pages/Patient/NotificationRules';
import PatientNotes from './pages/Patient/Notes';
import PatientPlanOfCare from './pages/Patient/PlanOfCare';
import PatientDocuments from './pages/Patient/Documents';
import PatientReports from './pages/Patient/Reports';
import Reports from './pages/Reports';
import Templates from './pages/Templates';
import AuditLogs from './pages/AuditLogs';
import StorageFile from './pages/StorageFile';

import muiTheme from './themes/muiTheme';
import {
  SIGN_IN,
  DASHBOARD,
  PROGRAMS,
  PROFILE,
  USERS,
  PATIENTS,
  INVENTORY,
  PATIENT_DASHBOARD,
  PATIENT_DEVICES,
  PATIENT_NOTIFICATION_RULES,
  PATIENT_NOTES,
  PATIENT_PLAN_OF_CARE,
  PATIENT_REPORTS,
  ACTIVITY_LOGS,
  ACTIVITY_LOGS_PATIENT,
  REPORTS,
  TEMPLATES,
  AUDIT_LOGS,
  PATIENT_DOCUMENTS,
  STORAGE_FILE,
} from './constants/paths';

import './assets/styles/app.scss';
import { useTrackUserActivity } from '~/utils/useTrackUserActivity';
import { setUpAxiosInterceptors } from '~/utils/setUpAxiosInterceptors';
import { UserPermission, UserRole } from '~/types';

const App = (): ReactElement => {
  const { onCheckAuthorization } = useStoreActions(actions => actions.auth);

  useEffect(() => {
    onCheckAuthorization();
  }, []);

  setUpAxiosInterceptors();
  useTrackUserActivity();

  return (
    <ThemeProvider theme={muiTheme}>
      <Router>
        <Switch>
          <PublicRoute exact path={SIGN_IN} component={SignIn} />
          <PrivateRoute exact path="/" component={Home} />
          <PrivateRoute exact path={DASHBOARD} component={Dashboard} />
          <PrivateRoute
            exact
            path={PROGRAMS}
            component={Programs}
            roles={[UserRole.ProgramAdmin, UserRole.SuperAdmin]}
          />
          <PrivateRoute exact path={PROFILE} component={Profile} />
          <PrivateRoute
            path={USERS}
            component={Users}
            roles={[UserRole.SuperAdmin]}
            permissions={[UserPermission.ManageUsers, UserPermission.ManageMobileUsers]}
          />
          <PrivateRoute
            exact
            path={PATIENTS}
            component={Patients}
            roles={[UserRole.SuperAdmin]}
            permissions={[UserPermission.ManagePatients, UserPermission.MonitorPatients]}
          />
          <PrivateRoute
            path={INVENTORY}
            component={Inventory}
            roles={[UserRole.SuperAdmin]}
            permissions={[UserPermission.ManageDevices]}
          />
          <PrivateRoute
            exact
            path={ACTIVITY_LOGS}
            component={ActivityLogs}
            roles={[UserRole.SuperAdmin]}
            permissions={[UserPermission.ManagePatients, UserPermission.MonitorPatients]}
          />
          <PrivateRoute
            exact
            path={ACTIVITY_LOGS_PATIENT}
            component={PatientActivityLogs}
            roles={[UserRole.SuperAdmin]}
            permissions={[UserPermission.ManagePatients, UserPermission.MonitorPatients]}
          />
          <PrivateRoute
            path={PATIENT_DASHBOARD}
            component={PatientDashboard}
            isPatientLayout
            roles={[UserRole.SuperAdmin]}
            permissions={[UserPermission.ManagePatients, UserPermission.MonitorPatients]}
          />
          <PrivateRoute
            exact
            path={PATIENT_DEVICES}
            component={PatientDevices}
            isPatientLayout
            roles={[UserRole.SuperAdmin]}
            permissions={[UserPermission.ManagePatients, UserPermission.MonitorPatients]}
          />
          <PrivateRoute
            exact
            path={PATIENT_NOTIFICATION_RULES}
            component={PatientNotificationRules}
            isPatientLayout
            roles={[UserRole.SuperAdmin]}
            permissions={[UserPermission.ManagePatients, UserPermission.MonitorPatients]}
          />
          <PrivateRoute
            exact
            path={PATIENT_NOTES}
            component={PatientNotes}
            isPatientLayout
            roles={[UserRole.SuperAdmin]}
            permissions={[UserPermission.ManagePatients, UserPermission.MonitorPatients]}
          />
          <PrivateRoute
            exact
            path={PATIENT_PLAN_OF_CARE}
            component={PatientPlanOfCare}
            isPatientLayout
            roles={[UserRole.SuperAdmin]}
            permissions={[UserPermission.ManagePatients, UserPermission.MonitorPatients]}
          />
          <PrivateRoute
            exact
            path={PATIENT_DOCUMENTS}
            component={PatientDocuments}
            isPatientLayout
            roles={[UserRole.SuperAdmin]}
            permissions={[UserPermission.ManagePatients, UserPermission.MonitorPatients]}
          />
          <PrivateRoute
            exact
            path={PATIENT_REPORTS}
            component={PatientReports}
            isPatientLayout
            roles={[UserRole.SuperAdmin]}
            permissions={[UserPermission.ManagePatients, UserPermission.MonitorPatients]}
          />
          <PrivateRoute
            exact
            path={REPORTS}
            component={Reports}
            roles={[UserRole.SuperAdmin]}
            permissions={[UserPermission.ManageReports]}
          />
          <PrivateRoute
            exact
            path={TEMPLATES}
            component={Templates}
            roles={[UserRole.SuperAdmin]}
          />
          <PrivateRoute
            exact
            path={AUDIT_LOGS}
            component={AuditLogs}
            roles={[UserRole.SystemAdmin]}
          />
          <PrivateRoute exact path={STORAGE_FILE} component={StorageFile} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
};
export default App;
