import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { getPaginationPayload } from '~/utils/pagination';
import { IThunkGetStorageFiles, IStorageFilesStore } from '../types';

const onGetStorageFiles: IThunkGetStorageFiles = thunk(
  // @ts-ignore
  async ({ setStorageFiles, reset }, filters, { getState }) => {
    reset();

    const localState = getState() as IStorageFilesStore;

    const paginationPayload = getPaginationPayload(localState.pagination);

    const { data } = await api.storageFiles.getList({
      ...filters,
      ...paginationPayload,
    });

    setStorageFiles(data);
  },
);

export default onGetStorageFiles;
