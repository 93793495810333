import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { getPaginationPayload } from '~/utils/pagination';
import { IThunkGetStatusUsers, IStatusUsersStore } from '../types';

const onGetStatusUsers: IThunkGetStatusUsers = thunk(
  // @ts-ignore
  async ({ setStatusUsers, reset }, payload, { getState }) => {
    reset();
    const localState = getState() as IStatusUsersStore;
    const paginationPayload = getPaginationPayload(localState.pagination);

    const { data } = await api.users.getByStatus({
      ...payload,
      ...paginationPayload,
    });

    setStatusUsers(data);
  },
);

export default onGetStatusUsers;
