import { ReactElement } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { IStatusUser } from '~/services/api/users/types';
import styles from './Item.module.scss';
import { isoToFormat } from '~/utils/date';

interface IProps {
  user: IStatusUser;
}

const Item = ({ user }: IProps): ReactElement => (
  <TableRow>
    <TableCell className={styles.cell}>{user.fullName}</TableCell>
    <TableCell>{user.roleName}</TableCell>
    <TableCell className={styles.cell}>{user.programs.map(x => x.name).join(', ')}</TableCell>
    <TableCell>{isoToFormat(user.createdAt, 'dd MMM y')}</TableCell>
  </TableRow>
);

export default Item;
