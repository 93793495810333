import { IStatusPatientsFilters } from '~/services/api/patients/types';
import { getCurrentMonthFirstDayDate, setEndOfDay } from '~/utils/date';
import { PatientsPopupType } from '../../types';

export const getInitialValues = (type: PatientsPopupType) =>
  ({
    fullName: '',
    subjectId: '',
    programId: '',
    startDate:
      type === PatientsPopupType.Admitted || type === PatientsPopupType.Discharged
        ? getCurrentMonthFirstDayDate()
        : '',
    endDate:
      type === PatientsPopupType.Admitted || type === PatientsPopupType.Discharged
        ? setEndOfDay(new Date())
        : '',
  } as IStatusPatientsFilters);

export default {};
