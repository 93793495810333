import { IStatusMobileUsersStore } from './types';

const initStore: IStatusMobileUsersStore = {
  statusMobileUsers: {
    total: 0,
    mobileUsers: [],
  },
  pagination: {
    page: 0,
    itemsPerPage: 20,
    hasMore: false,
  },
};

export default initStore;
