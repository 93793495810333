import { ReactElement } from 'react';

interface IProps {
  name: string;
  color: string;
}

const FileTypeIcon = ({ name, color }: IProps): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M20.2499 5.10379V20C20.2499 21.7949 18.7948 23.25 16.9999 23.25H7C5.20508 23.25 3.75 21.7949 3.75 20V4C3.75 2.20508 5.20507 0.75 7 0.75H15.5098L20.2499 5.10379Z"
      stroke="#D0D5DD"
      strokeWidth="1.5"
    />
    <mask id="path-2-inside-1_5625_62605" fill="white">
      <path d="M15 0.599609H20.1V5.69961H16C15.4477 5.69961 15 5.25189 15 4.69961V0.599609Z" />
    </mask>
    <path
      d="M15 0.599609H20.1H15ZM20.1 6.69961H16C14.8954 6.69961 14 5.80418 14 4.69961H16H20.1V6.69961ZM16 6.69961C14.8954 6.69961 14 5.80418 14 4.69961V0.599609H16V4.69961V6.69961ZM20.1 0.599609V5.69961V0.599609Z"
      fill="#D0D5DD"
      mask="url(#path-2-inside-1_5625_62605)"
    />
    <rect x="0" y="12" width="18" height="8" rx="2" fill={color} />

    <text
      xmlns="http://www.w3.org/2000/svg"
      fill="white"
      x="9"
      y="18"
      fontSize="6px"
      fontWeight="bold"
      textAnchor="middle"
    >
      {name}
    </text>
  </svg>
);

export default FileTypeIcon;
