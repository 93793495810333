import { ReactElement } from 'react';
import MobileUsersPopup from '../reusable/MobileUsersPopup';

interface IProps {
  onClose: () => void;
}

const ActiveMobileUsers = ({ onClose }: IProps): ReactElement => (
  <MobileUsersPopup onClose={onClose} isActive title="Active Mobile Users" />
);

export default ActiveMobileUsers;
