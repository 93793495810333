import { ReactElement, useState, useEffect } from 'react';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import Header from './Header';
import Table from './Table';
import Modal from '~/ui/components/common/Modal';
import Loader from '~/ui/components/common/Loader';
import { IStatusMobileUsersFilters } from '~/services/api/mobileUsers/types';
import styles from './MobileUsersPopup.module.scss';
import { getInitialValues } from './Header/form';

interface IProps {
  isActive: boolean;
  title: string;
  onClose: () => void;
}

const MobileUsersPopup = ({ isActive, title, onClose }: IProps): ReactElement => {
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<IStatusMobileUsersFilters>(getInitialValues());

  const { pagination, statusMobileUsers } = useStoreState(state => state.statusMobileUsers);
  const { onGetStatusMobileUsers, onGetMoreStatusMobileUsers } = useStoreActions(
    actions => actions.statusMobileUsers,
  );
  const showError = useStoreActions(actions => actions.snackbar.showError);

  const payload = { isActive, ...filters };

  const onMount = async () => {
    setLoading(true);
    try {
      await onGetStatusMobileUsers(payload);
      setLoading(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const onLoadMore = () => {
    onGetMoreStatusMobileUsers(payload);
  };

  useEffect(() => {
    onMount();
  }, [filters]);

  return (
    <Modal open onClose={onClose} className={styles.modal} maxWidth="lg" title={title}>
      <div className={styles.container}>
        <Header isActive={isActive} total={statusMobileUsers.total} onSubmit={setFilters} />
        {loading ? (
          <Loader />
        ) : (
          <Table
            mobileUsers={statusMobileUsers.mobileUsers}
            onLoadMore={onLoadMore}
            pagination={pagination}
          />
        )}
      </div>
    </Modal>
  );
};

export default MobileUsersPopup;
