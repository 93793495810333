import { useState, ReactElement, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Grid, GridSize, Paper } from '@material-ui/core';
import * as Highcharts from 'highcharts';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import Loader from '~/ui/components/common/Loader';
import Header from './Header';
import Readings from './Readings';

import { getInitialFilters } from './constants';
import { deviceTypeDefinitions } from '~/ui/pages/Dashboard/popups/DevicesPopup/constants';
import { formatToIsoWithTimezone } from '~/utils/date';
import {
  IPatientInfo,
  IPatientOverview,
  IPatientOverviewFilters,
} from '~/services/api/patients/types';
import NoDataFound from '~/ui/components/reusable/NoDataFound';
import {
  DeviceType,
  PatientOverviewAverageType,
  TableCustomizationType,
} from '~/services/api/enums';

import MainChart from './Charts/MainChart';
import MorningEvening from './Charts/MorningEvening';
import NotificationsSummary from './Charts/NotificationsSummary';
import ReadingCount from './Charts/ReadingCount';
import ReadingSummary from './Charts/ReadingSummary';
import WeeklyAnalysis from './Charts/WeeklyAnalysis';
import WeeklyGraphs from './Charts/WeeklyGraphs';
import { ITableCustomization } from '~/services/api/tableCustomizations/types';

import styles from './Overview.module.scss';
import { IGetOverview } from '~/store/patient/types';
import Empty from './Empty';
import Average from './Charts/Average';
import { smallCells } from '../../constants';

interface IProps {
  patientInfo: IPatientInfo;
}

const Overview = ({ patientInfo }: IProps): ReactElement => {
  const location = useLocation();
  const history = useHistory();

  const queryParams = new URLSearchParams(location.search);

  const [isLoading, setIsLoading] = useState(true);
  const [patientOverview, setPatientOverview] = useState(null as IPatientOverview);
  const [deviceTypeId, setDeviceTypeId] = useState(null as DeviceType);
  const [patientOverviewPayload, setPatientOverviewPayload] = useState(null as IGetOverview);

  const tableCustomizations = useStoreState(state => state.tableCustomizations.items)[
    TableCustomizationType.PatientOverview
  ];

  const onGetPatientOverview = useStoreActions(actions => actions.patient.onGetOverview);
  const { onGetTableCustomizationsByTypeId } = useStoreActions(
    actions => actions.tableCustomizations,
  );
  const showError = useStoreActions(actions => actions.snackbar.showError);

  const deviceTypeOptions = Object.values(deviceTypeDefinitions).filter(x =>
    patientInfo.deviceTypeIds.includes(x.value),
  );

  if (deviceTypeOptions.length === 0) {
    return <NoDataFound />;
  }

  const queryDeviceTypeId = queryParams.get('deviceTypeId') as DeviceType;

  const defaultInitialFilters = {
    ...getInitialFilters(),
    deviceTypeId: deviceTypeOptions.some(x => x.value === queryDeviceTypeId)
      ? queryDeviceTypeId
      : deviceTypeOptions[0].value,
    startDate: (queryParams.get('startDate') as DeviceType) ?? getInitialFilters().startDate,
    endDate: (queryParams.get('endDate') as DeviceType) ?? getInitialFilters().endDate,
  };

  const getData = async (filters: IPatientOverviewFilters) => {
    try {
      setIsLoading(true);

      history.push({
        pathname: location.pathname,
        search: new URLSearchParams({
          deviceTypeId: filters.deviceTypeId,
          startDate: filters.startDate,
          endDate: filters.endDate,
        }).toString(),
      });

      const payload = {
        patientId: patientInfo.id,
        filters,
        params: {
          ...filters,
          startDate: formatToIsoWithTimezone(filters.startDate, patientInfo.timezone),
          endDate: formatToIsoWithTimezone(filters.endDate, patientInfo.timezone),
        },
      };

      setPatientOverviewPayload(payload);

      const data = await onGetPatientOverview(payload);

      setPatientOverview(data);
      setDeviceTypeId(filters.deviceTypeId);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsLoading(false);
    }
  };

  const onReload = async () => {
    const data = await onGetPatientOverview(patientOverviewPayload);

    setPatientOverview(data);
  };

  const getTableCustomizations = async () => {
    try {
      await onGetTableCustomizationsByTypeId(TableCustomizationType.PatientOverview);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  useEffect(() => {
    getTableCustomizations();
  }, []);

  useEffect(() => {
    Highcharts.charts.forEach(x => x?.reflow());
  }, [tableCustomizations]);

  const renderCell = (tableCustomization: ITableCustomization) => {
    switch (tableCustomization.columnKey) {
      case 'generalGraphs':
        return <MainChart readings={patientOverview.readings} deviceTypeId={deviceTypeId} />;
      case 'monthlyAverage':
        return (
          <Average
            type={PatientOverviewAverageType.Monthly}
            deviceTypeId={deviceTypeId}
            patientOverview={patientOverview}
          />
        );
      case 'weeklyAverage':
        return (
          <Average
            type={PatientOverviewAverageType.Weekly}
            deviceTypeId={deviceTypeId}
            patientOverview={patientOverview}
          />
        );
      case 'readingSummary':
        return <ReadingSummary readings={patientOverview.readings} deviceTypeId={deviceTypeId} />;
      case 'notificationsSummary':
        return (
          <NotificationsSummary
            notificationRules={patientOverview.notificationRules}
            readings={patientOverview.readings}
            deviceTypeId={deviceTypeId}
          />
        );
      case 'averageReadingsMorningEvening':
        return <MorningEvening readings={patientOverview.readings} deviceTypeId={deviceTypeId} />;
      case 'readingCountOverPeriod':
        return <ReadingCount readings={patientOverview.readings} />;
      case 'weeklyAnalysis':
        return <WeeklyAnalysis readings={patientOverview.readings} deviceTypeId={deviceTypeId} />;
      case 'weeklyGraphs':
        return <WeeklyGraphs readings={patientOverview.readings} deviceTypeId={deviceTypeId} />;
      case 'readings':
        return (
          <Readings
            patientOverview={patientOverview}
            deviceTypeId={deviceTypeId}
            onReload={onReload}
          />
        );
      default:
        throw new Error(`Invalid columnKey: ${tableCustomization.columnKey}.`);
    }
  };

  const visibleTableCustomizations = tableCustomizations.filter(x => x.isVisible);

  const addSmallCell = (
    result: (ITableCustomization[] | ITableCustomization)[],
    tableCustomization: ITableCustomization,
  ) => {
    const last = result[result.length - 1];

    return Array.isArray(last) && last.length === 1
      ? [...result.slice(0, -1), [...last, tableCustomization]]
      : [...result, [tableCustomization]];
  };

  const tableCustomizationConfigs = visibleTableCustomizations
    .reduce(
      (result, x) => (smallCells.includes(x.columnKey) ? addSmallCell(result, x) : [...result, x]),
      [] as (ITableCustomization[] | ITableCustomization)[],
    )
    .reduce(
      (result, x) => ({
        ...result,
        ...(Array.isArray(x)
          ? x.reduce((subResult, subX) => ({ ...subResult, [subX.columnKey]: 12 / x.length }), {})
          : { [x.columnKey]: 12 }),
      }),
      {} as { [key: string]: GridSize },
    );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Header
          onSubmit={getData}
          deviceTypeOptions={deviceTypeOptions}
          defaultValues={defaultInitialFilters}
          patientOverview={patientOverview}
        />
      </Grid>
      {isLoading ? (
        <Grid item xs={12}>
          <Loader />
        </Grid>
      ) : (
        <>
          {visibleTableCustomizations.length > 0 ? (
            visibleTableCustomizations.map(tableCustomization => (
              <Grid
                key={tableCustomization.columnKey}
                item
                xs={tableCustomizationConfigs[tableCustomization.columnKey]}
              >
                <Paper elevation={24} square className={styles.container}>
                  {renderCell(tableCustomization)}
                </Paper>
              </Grid>
            ))
          ) : (
            <Empty />
          )}
        </>
      )}
    </Grid>
  );
};

export default Overview;
