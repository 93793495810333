import { INotificationRule } from '~/services/api/notificationRules/types';
import { IProgram } from '~/services/api/programs/types';
import { Timezone } from '~/types';

const initialValues = {
  name: '',
  timezone: '' as Timezone,
  imageStorageFileId: '',
  imageStorageFileUrl: '',
  rules: [] as INotificationRule[],
} as IProgram;

export default initialValues;
