import { useEffect, useState, ReactElement } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';

import { useStoreState, useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import Loader from '~/ui/components/common/Loader';
import Item from './Item';

import { UserRole } from '~/types';
import NoDataFound from '~/ui/components/reusable/NoDataFound';
import api from '~/services/api';
import Header from '../Header';
import { getInitialFilters } from '../Header/form';
import { IUsersListFilters, IUsersSelectors } from '~/services/api/users/types';

const SuperAdmin = (): ReactElement => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSelectorsLoading, setIsSelectorsLoading] = useState(true);
  const [filters, setFilters] = useState<IUsersListFilters>({ ...getInitialFilters() });
  const [selectors, setSelectors] = useState<IUsersSelectors>(null);

  const { items: users, itemsPagination: pagination } = useStoreState(state => state.users);
  const { onGetUsers, onGetMoreUsers } = useStoreActions(actions => actions.users);
  const showError = useStoreActions(actions => actions.snackbar.showError);

  const params = {
    roleId: UserRole.SuperAdmin,
    ...filters,
  };

  const getSelectors = async () => {
    setIsSelectorsLoading(true);

    try {
      const { data } = await api.users.getSelectors();

      setSelectors(data);

      setIsSelectorsLoading(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const getData = async () => {
    setIsLoading(true);

    try {
      await onGetUsers(params);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsLoading(false);
    }
  };

  const onLoadMore = async () => {
    try {
      await onGetMoreUsers(params);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  useEffect(() => {
    getData();
  }, [filters]);

  useEffect(() => {
    getSelectors();
  }, []);

  return (
    <>
      {!isSelectorsLoading && (
        <Header selectors={selectors} isProgramVisible={false} onSubmit={setFilters} />
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <InfiniteScroll
          dataLength={users.length}
          next={onLoadMore}
          hasMore={pagination.hasMore}
          loader={<Loader />}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Login Email</TableCell>
                  <TableCell>Login Date</TableCell>
                  <TableCell>Edit</TableCell>
                  <TableCell align="center">Remove</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.length ? (
                  users.map(item => <Item key={item.id} user={item} />)
                ) : (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <NoDataFound />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </InfiniteScroll>
      )}
    </>
  );
};

export default SuperAdmin;
