import { ReactElement } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { IPatientOverviewAverageInterval } from '~/services/api/patients/types';

interface IProps {
  interval: IPatientOverviewAverageInterval;
}

const Item = ({ interval }: IProps): ReactElement => (
  <TableRow>
    <TableCell>{interval.label}</TableCell>
    {interval.indicators.map(indicator => (
      <TableCell key={indicator.name} align="center">
        {indicator.value ?? '-'}
      </TableCell>
    ))}
  </TableRow>
);

export default Item;
