import { useMemo } from 'react';
import { ICurrentUser } from '~/services/api/auth/types';
import { UserPermission } from '~/types';

export const useIsActivityCounterEnabled = (currentUser: ICurrentUser) =>
  useMemo(
    () => currentUser?.permissions.includes(UserPermission.TimeAccrual) ?? false,
    [currentUser],
  );

export default {};
