import { useEffect, useState, ReactElement } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Paper from '@material-ui/core/Paper';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';

import { Grid } from '@mui/material';
import api from '~/services/api';
import { useStoreState, useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import Loader from '~/ui/components/common/Loader';
import Header from './Header';
import Table from './Table';

import styles from './Documents.module.scss';
import {
  IStorageFilesListFilters,
  IStorageFilesSelectors,
} from '~/services/api/storageFiles/types';
import { getInitialFilters } from './Header/form';
import { ISort } from '~/utils/sort/types';
import { initialSort } from './Table/constants';
import Button from '~/ui/components/common/Button';
import UploadDocumentsModal from './UploadDocumentsModal';
import { StorageFileArea } from '~/services/api/enums';
import { formatToIsoWithTimezone, setEndOfDay } from '~/utils/date';

const Documents = (): ReactElement => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSelectorsLoading, setIsSelectorsLoading] = useState(true);
  const [filters, setFilters] = useState<IStorageFilesListFilters>(getInitialFilters());
  const [selectors, setSelectors] = useState<IStorageFilesSelectors>(null);
  const [sort, setSort] = useState<ISort>({ ...initialSort });
  const [isUploadDocumentsModalOpen, setIsUploadDocumentsModalOpen] = useState(false);

  const patientInfo = useStoreState(state => state.patient.info);
  const { items, pagination } = useStoreState(state => state.storageFiles);

  const { onGetStorageFiles, onGetMoreStorageFiles } = useStoreActions(
    actions => actions.storageFiles,
  );
  const showError = useStoreActions(actions => actions.snackbar.showError);

  const params = {
    ...filters,
    ...sort,
    patientId: patientInfo.id,
    startDate: formatToIsoWithTimezone(filters.startDate, patientInfo.timezone),
    endDate: formatToIsoWithTimezone(setEndOfDay(filters.endDate), patientInfo.timezone),
  };

  const getData = async () => {
    setIsLoading(true);

    try {
      await onGetStorageFiles(params);

      setIsLoading(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const getSelectors = async () => {
    setIsSelectorsLoading(true);

    try {
      const { data } = await api.storageFiles.getSelectorsByArea(StorageFileArea.PatientDocuments);

      setSelectors(data);

      setIsSelectorsLoading(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const onLoadMore = async () => {
    try {
      await onGetMoreStorageFiles(params);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  useEffect(() => {
    getData();
  }, [filters, sort]);

  useEffect(() => {
    getSelectors();
  }, []);

  const onAddNewClick = () => {
    setIsUploadDocumentsModalOpen(true);
  };

  const onUploadDocumentsModalClose = () => {
    setIsUploadDocumentsModalOpen(false);
    getData();
  };

  return (
    <>
      <Grid container className={styles.header} alignItems="center" justifyContent="space-between">
        <Grid item className={styles.title}>
          Documents
        </Grid>
        <Grid item className={styles.addButton}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            startIcon={<AddCircleOutline />}
            onClick={onAddNewClick}
          >
            Add New
          </Button>
        </Grid>
      </Grid>
      <Paper elevation={3} className={styles.container}>
        {!isSelectorsLoading && <Header selectors={selectors} onSubmit={setFilters} />}
        {!isLoading && (
          <InfiniteScroll
            dataLength={items.length}
            next={onLoadMore}
            hasMore={pagination.hasMore}
            loader={<Loader />}
          >
            <Table items={items} sort={sort} setSort={setSort} />
          </InfiniteScroll>
        )}
        {isLoading && <Loader />}
      </Paper>
      {isUploadDocumentsModalOpen && (
        <UploadDocumentsModal
          patientId={patientInfo.id}
          storageFileArea={StorageFileArea.PatientDocuments}
          selectors={selectors}
          onClose={onUploadDocumentsModalClose}
        />
      )}
    </>
  );
};

export default Documents;
