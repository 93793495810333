import { ReactElement } from 'react';

import { useStoreState } from '~/store/hooks';
import { AccountSettingsForm, ChangePasswordForm } from './form';
import Wrapper from './components/Wrapper';

import { UserRole } from '~/types';
import styles from './Profile.module.scss';

const Profile = (): ReactElement => {
  const userRole = useStoreState(state => state.auth.currentUser?.roleId);

  const permissions = {
    showChangePasssword: [
      UserRole.ProgramAdmin,
      UserRole.SuperAdmin,
      UserRole.SystemAdmin,
    ].includes(userRole),
  };

  return (
    <>
      <Wrapper title="Account Settings">
        <div className={styles.inputsContainer}>
          <AccountSettingsForm />
        </div>
      </Wrapper>
      {permissions.showChangePasssword && (
        <Wrapper title="Change Password">
          <div className={styles.inputsContainer}>
            <ChangePasswordForm />
          </div>
        </Wrapper>
      )}
    </>
  );
};

export default Profile;
