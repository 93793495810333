import { ReactElement, useState, useEffect } from 'react';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import Header from './Header';
import Table from './Table';
import Modal from '~/ui/components/common/Modal';
import Loader from '~/ui/components/common/Loader';
import { PatientsPopupType } from './types';
import { IStatusPatientsFilters } from '~/services/api/patients/types';
import { IPagePermissions } from '../../../types';
import styles from './PatientsPopup.module.scss';
import { PatientStatus } from '~/services/api/enums';
import { getInitialValues } from './Header/form';

interface IProps {
  type: PatientsPopupType;
  permissions: IPagePermissions;
  status: PatientStatus;
  orderBy?: string;
  isDescending?: boolean;
  title: string;
  onClose: () => void;
}

const PatientsPopup = ({
  type,
  permissions,
  status,
  orderBy,
  isDescending,
  title,
  onClose,
}: IProps): ReactElement => {
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<IStatusPatientsFilters>(getInitialValues(type));

  const { pagination, statusPatients } = useStoreState(state => state.statusPatients);
  const { onGetStatusPatients, onGetMoreStatusPatients } = useStoreActions(
    actions => actions.statusPatients,
  );
  const showError = useStoreActions(actions => actions.snackbar.showError);

  const payload = { status, orderBy, isDescending, ...filters };

  const onMount = async () => {
    setLoading(true);
    try {
      await onGetStatusPatients(payload);
      setLoading(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const onLoadMore = () => {
    onGetMoreStatusPatients(payload);
  };

  useEffect(() => {
    onMount();
  }, [filters]);

  return (
    <Modal open onClose={onClose} className={styles.modal} maxWidth="lg" title={title}>
      <div className={styles.container}>
        <Header type={type} total={statusPatients.total} onSubmit={setFilters} />
        {loading ? (
          <Loader />
        ) : (
          <Table
            type={type}
            patients={statusPatients.patients}
            permissions={permissions}
            onLoadMore={onLoadMore}
            pagination={pagination}
          />
        )}
      </div>
    </Modal>
  );
};

export default PatientsPopup;
