import { object, array, number } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const validationSchema = object().shape({
  items: array()
    .of(
      object().shape({
        id: number().required(),
        documentTypeId: number().nullable().required('Field is required'),
      }),
    )
    .min(1),
});

export default yupResolver(validationSchema);
