import { ReactElement } from 'react';
import PatientsPopup from '../reusable/PatientsPopup';
import { IPagePermissions } from '../../types';
import { PatientStatus } from '~/services/api/enums';
import { PatientsPopupType } from '../reusable/PatientsPopup/types';

interface IProps {
  permissions: IPagePermissions;
  onClose: () => void;
}

const DischargedPatients = ({ permissions, onClose }: IProps): ReactElement => (
  <PatientsPopup
    type={PatientsPopupType.Discharged}
    permissions={permissions}
    status={PatientStatus.Discharged}
    orderBy="dischargedAt"
    isDescending
    title="Discharged Patients"
    onClose={onClose}
  />
);

export default DischargedPatients;
