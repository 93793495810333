import { action } from 'easy-peasy';
import initStore from '../initStore';
import { IActionReset } from '../types';

const reset: IActionReset = action(state => {
  const { statusPatients, pagination } = initStore;

  state.statusPatients = statusPatients;
  state.pagination = pagination;
});

export default reset;
