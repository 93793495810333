import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { getPaginationPayload } from '~/utils/pagination';
import { IStorageFilesStore, IThunkGetMoreStorageFiles } from '../types';

const onGetMoreStorageFiles: IThunkGetMoreStorageFiles = thunk(
  // @ts-ignore
  async ({ setMoreStorageFiles }, filters, { getState }) => {
    const localState = getState() as IStorageFilesStore;

    const paginationPayload = getPaginationPayload(localState.pagination);

    const { data } = await api.storageFiles.getList({
      ...filters,
      ...paginationPayload,
    });

    setMoreStorageFiles(data);
  },
);

export default onGetMoreStorageFiles;
