import { ReactElement, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@mui/icons-material/Search';
import RotateRightIcon from '@mui/icons-material/RotateRight';

import { getInitialFilters, validate } from './form';
import Select from '~/ui/components/inputs/Select';
import Button from '~/ui/components/common/Button';

import {
  IStorageFilesListFilters,
  IStorageFilesSelectors,
} from '~/services/api/storageFiles/types';
import { allOption } from '~/ui/constants/allOptions';
import Input from '~/ui/components/inputs/Input';

import styles from './Header.module.scss';
import DatePicker from '~/ui/components/inputs/DatePicker';

interface IProps {
  selectors: IStorageFilesSelectors;
  onSubmit: (filters: IStorageFilesListFilters) => void;
}

const Header = ({ selectors, onSubmit }: IProps): ReactElement => {
  const initialFilters = getInitialFilters();

  const formMethods = useForm<IStorageFilesListFilters>({
    resolver: validate,
    defaultValues: initialFilters,
  });

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = formMethods;

  const storageFileTypeOptions = useMemo(
    () => [
      allOption,
      ...selectors.storageFileTypes.map(item => ({
        value: item.id,
        label: item.name,
      })),
    ],
    [selectors.storageFileTypes],
  );

  const documentTypeOptions = useMemo(
    () => [
      allOption,
      ...selectors.documentTypes.map(item => ({
        value: item.id,
        label: item.name,
      })),
    ],
    [selectors.documentTypes],
  );

  const onSubmitForm = (values: IStorageFilesListFilters) => {
    onSubmit(values);
  };

  return (
    <FormProvider {...formMethods}>
      <form className={styles.container} onSubmit={handleSubmit(onSubmitForm)}>
        <Grid container spacing={2}>
          <Grid item xs>
            <DatePicker
              placeholder=".. /.. /.."
              label="Start Date"
              name="startDate"
              maxDate={new Date()}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item xs>
            <DatePicker
              placeholder=".. /.. /.."
              label="End Date"
              name="endDate"
              maxDate={new Date()}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item xs>
            <Select
              name="typeId"
              control={control}
              errors={errors}
              options={storageFileTypeOptions}
              label="File Type"
            />
          </Grid>
          <Grid item xs>
            <Select
              name="documentTypeId"
              control={control}
              errors={errors}
              options={documentTypeOptions}
              label="Document Type"
            />
          </Grid>
          <Grid item xs>
            <Input name="name" register={register} errors={errors} label="Name" />
          </Grid>
          <Grid item xs={2} />
          <Grid item>
            <Button variant="outlined" type="submit" color="primary" startIcon={<SearchIcon />}>
              Search
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              type="button"
              startIcon={<RotateRightIcon />}
              onClick={() => {
                reset(initialFilters);
                onSubmit(initialFilters);
              }}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default Header;
