import { ISort } from '~/utils/sort/types';

interface ICell {
  id: string;
  label: string;
  useSort: boolean;
  width?: number;
  align?: any;
}

export const headCells: ICell[] = [
  {
    id: 'typeId',
    label: 'File Type',
    useSort: false,
    align: 'center',
  },
  {
    id: 'createdAt',
    label: 'Uploaded Date',
    useSort: false,
    align: 'center',
  },
  {
    id: 'documentTypeId',
    label: 'Document Type',
    useSort: false,
  },
  {
    id: 'name',
    label: 'Name',
    useSort: false,
  },
  {
    id: 'createdByUserId',
    label: 'Author',
    useSort: false,
  },
  {
    id: 'actions',
    label: '',
    useSort: false,
    width: 120,
    align: 'center',
  },
];

export const initialSort: ISort = {
  orderBy: 'name',
  isDescending: false,
};

export default {};
