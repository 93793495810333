import { ReactElement, useCallback, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import CancelIcon from '@mui/icons-material/Cancel';
import { IconButton, LinearProgress } from '@mui/material';
import { IStorageFileItem } from '../types';
import styles from './StorageFileItem.module.scss';
import { useStoreActions } from '~/store/hooks';
import { IStorageFile } from '~/services/api/storageFiles/types';
import { extractErrorMessage } from '~/utils/error';
import { StorageFileArea } from '~/services/api/enums';
import buildFileFormData from '~/utils/buildFileFormData';

interface IProps {
  storageFileArea: StorageFileArea;
  storageFileItem: IStorageFileItem;
  onCancel: () => void;
  onUploaded: (storageFile: IStorageFile) => void;
}

const StorageFileItem = ({
  storageFileArea,
  storageFileItem,
  onCancel,
  onUploaded,
}: IProps): ReactElement => {
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState('');

  const { onCreateStorageFile } = useStoreActions(actions => actions.storageFiles);

  const onUploadProgress = useCallback(
    (event: ProgressEvent) => {
      setProgress((event.loaded / event.total) * 100);
    },
    [setProgress],
  );

  useEffect(() => {
    const abortController = new AbortController();

    const promise = onCreateStorageFile({
      storageFileArea,
      data: buildFileFormData(storageFileItem.file),
      abortController,
      onUploadProgress,
    });

    promise.then(
      storageFile => onUploaded(storageFile),
      reason => setError(extractErrorMessage(reason)),
    );

    return () => abortController.abort();
  }, []);

  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <Grid
          container
          className={styles.container}
          alignItems="center"
          justifyContent="space-between"
          wrap="nowrap"
        >
          <Grid item className={styles.name}>
            {storageFileItem.name}
          </Grid>
          <Grid item>
            <IconButton className={styles.cancel} title="Cancel" onClick={onCancel}>
              <CancelIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <LinearProgress variant="determinate" value={progress} />
      </Grid>
      {error && (
        <Grid item className={styles.error}>
          {error}
        </Grid>
      )}
    </Grid>
  );
};

export default StorageFileItem;
