import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkCreateStorageFilesByPatientId } from '../types';

// @ts-ignore
const onCreateStorageFilesByPatientId: IThunkCreateStorageFilesByPatientId = thunk(
  async (_, { patientId, data }) => {
    await api.storageFiles.createByPatientId(patientId, data);
  },
);

export default onCreateStorageFilesByPatientId;
