import { ReactElement } from 'react';
import classnames from 'classnames';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';

import { getSort } from '~/utils/sort';
import NoDataFound from '~/ui/components/reusable/NoDataFound';
import Item from './Item';

import { cells } from './constants';
import { Order } from '~/types';
import { IPatientList } from '~/services/api/patients/types';
import { ISort } from '~/utils/sort/types';
import { IPagePermissions } from '../types';
import styles from './Table.module.scss';
import { ITableCustomization } from '~/services/api/tableCustomizations/types';

interface IProps {
  patients: IPatientList[];
  tableCustomizations: ITableCustomization[];
  isLoading: boolean;
  sort: ISort;
  permissions: IPagePermissions;
  onSort: (sort: ISort) => void;
  openLogActivitiesSetPatient: (patientId: number) => void;
  onRemove: (id: number) => void;
}

const TableComponent = ({
  patients,
  tableCustomizations,
  isLoading,
  sort,
  onSort,
  openLogActivitiesSetPatient,
  permissions,
  onRemove,
}: IProps): ReactElement => (
  <Paper>
    <TableContainer className={classnames({ [styles.hide]: isLoading })}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell key="" />
            {tableCustomizations
              .filter(x => x.isVisible)
              .map(x => cells[x.columnKey])
              .map(cell => (
                <TableCell
                  key={cell.id}
                  align={cell.align ?? 'inherit'}
                  className={classnames({ [styles.cellCenter]: cell.align === 'center' })}
                >
                  {cell.useSort ? (
                    <TableSortLabel
                      active={sort.orderBy === cell.id}
                      direction={
                        sort.orderBy === cell.id && sort.isDescending ? Order.Desc : Order.Asc
                      }
                      onClick={() => onSort(getSort(sort, cell.id))}
                    >
                      {cell.label}
                    </TableSortLabel>
                  ) : (
                    cell.label
                  )}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {patients.length ? (
            patients.map(item => (
              <Item
                key={item.id}
                patient={item}
                tableCustomizations={tableCustomizations}
                permissions={permissions}
                openLogActivitiesSetPatient={openLogActivitiesSetPatient}
                onRemove={onRemove}
              />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={10}>
                <NoDataFound />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  </Paper>
);

export default TableComponent;
