import { action } from 'easy-peasy';
import { IActionSetStatusMobileUsers } from '../types';

const setStatusMobileUsers: IActionSetStatusMobileUsers = action((state, payload) => {
  const hasMore = state.pagination.itemsPerPage === payload.mobileUsers.length;

  state.statusMobileUsers.total = payload.total;
  state.statusMobileUsers.mobileUsers = payload.mobileUsers;
  state.pagination.page += 1;
  state.pagination.hasMore = hasMore;
});

export default setStatusMobileUsers;
