import { ReactElement, useState } from 'react';

import { useStoreState } from '~/store/hooks';
import AccessDeniedPage from '~/ui/components/layouts/AccessDeniedPage';
import Overview from './components/Overview';
import PersonalData from './components/PersonalData';

import { UserRole, UserPermission } from '~/types';
import { PATIENT_DASHBOARD_OVERVIEW, PATIENT_DASHBOARD_PERSONAL_DATA } from '~/ui/constants/paths';
import RoutableTabs from '~/ui/components/common/RoutableTabs';
import { TableCustomizationType } from '~/services/api/enums';
import EditTableModal from '~/ui/components/reusable/EditTableModal';
import { cells } from './constants';

const PatientDashboard = (): ReactElement => {
  const [isCustomizingDashboard, setIsCustomizingDashboard] = useState(false);

  const userRole = useStoreState(state => state.auth.currentUser?.roleId);
  const userPermissions = useStoreState(state => state.auth.currentUser?.permissions || []);
  const patientInfo = useStoreState(state => state.patient.info);

  const permissions = {
    accessDenied:
      ![UserRole.SuperAdmin, UserRole.SystemAdmin].includes(userRole) &&
      ![UserPermission.ManagePatients, UserPermission.MonitorPatients].some(permission =>
        userPermissions.includes(permission),
      ),
  };

  if (permissions.accessDenied) {
    return <AccessDeniedPage />;
  }

  const tabs = [
    {
      label: 'Overview',
      path: PATIENT_DASHBOARD_OVERVIEW,
      route: PATIENT_DASHBOARD_OVERVIEW.replace(':id', String(patientInfo.id)),
      component: <Overview patientInfo={patientInfo} />,
      buttonLabel: 'Customize Dashboard',
      onButtonClick: () => setIsCustomizingDashboard(true),
    },
    {
      label: 'Personal Data',
      path: PATIENT_DASHBOARD_PERSONAL_DATA,
      route: PATIENT_DASHBOARD_PERSONAL_DATA.replace(':id', String(patientInfo.id)),
      component: <PersonalData patientId={patientInfo.id} />,
    },
  ];

  return (
    <>
      <RoutableTabs tabs={tabs} />
      {isCustomizingDashboard && (
        <EditTableModal
          typeId={TableCustomizationType.PatientOverview}
          title="Customize Dashboard"
          cells={cells}
          onClose={() => setIsCustomizingDashboard(false)}
        />
      )}
    </>
  );
};

export default PatientDashboard;
