import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { getPaginationPayload } from '~/utils/pagination';
import { IThunkGetStatusMobileUsers, IStatusMobileUsersStore } from '../types';

const onGetStatusMobileUsers: IThunkGetStatusMobileUsers = thunk(
  // @ts-ignore
  async ({ setStatusMobileUsers, reset }, payload, { getState }) => {
    reset();
    const localState = getState() as IStatusMobileUsersStore;
    const paginationPayload = getPaginationPayload(localState.pagination);

    const { data } = await api.mobileUsers.getByStatus({
      ...payload,
      ...paginationPayload,
    });

    setStatusMobileUsers(data);
  },
);

export default onGetStatusMobileUsers;
