import { useState, useEffect, ReactElement } from 'react';
import { useParams } from 'react-router-dom';

import { useStoreState, useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import { typeOptions } from '~/ui/constants/notificationRule';
import Loader from '~/ui/components/common/Loader';
import Table from './components/Table';
import Form from './components/Form';

import { UserRole, UserPermission } from '~/types';
import { INotificationRule } from '~/services/api/notificationRules/types';

interface PatientParams {
  id: string;
}

const PatientNotificationRules = (): ReactElement => {
  const { id } = useParams<PatientParams>();

  const userRole = useStoreState(state => state.auth.currentUser?.roleId);
  const userPermissions = useStoreState(state => state.auth.currentUser?.permissions || []);
  const patientNotificationRules = useStoreState(state => state.patientNotificationRules.items);
  const showError = useStoreActions(actions => actions.snackbar.showError);
  const {
    onGetPatientNotificationRules,
    onCreatePatientNotificationRule,
    onDeletePatientNotificationRule,
  } = useStoreActions(actions => actions.patientNotificationRules);

  const [showAddNotificationRule, setShowNotificationRule] = useState(false);
  const [loading, setLoading] = useState(false);

  const permissions = {
    canManageNotificationRules:
      [UserRole.SuperAdmin, UserRole.SystemAdmin].includes(userRole) ||
      userPermissions.includes(UserPermission.ManageNotifications),
  };

  const onMount = async () => {
    try {
      setLoading(true);
      await onGetPatientNotificationRules({ patientId: Number(id) });
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  const addNotificationRule = async (newRule: INotificationRule) => {
    try {
      await onCreatePatientNotificationRule({ patientId: Number(id), data: newRule });
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setShowNotificationRule(false);
    }
  };

  const removeNotificationRule = async (currentId: number) => {
    try {
      await onDeletePatientNotificationRule(currentId);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  // For each typeOptions we have 2 cases, thefore we multiple by 2
  const disabledButton = patientNotificationRules.length === typeOptions.length * 2;

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <Table
          notificationRules={patientNotificationRules}
          permissions={permissions}
          removeNotificationRule={removeNotificationRule}
        />
      )}
      {permissions.canManageNotificationRules && (
        <Form
          showAddNotificationRule={showAddNotificationRule}
          notificationRules={patientNotificationRules}
          addNotificationRule={addNotificationRule}
          setShowNotificationRule={setShowNotificationRule}
          disabledButton={disabledButton}
        />
      )}
    </div>
  );
};

export default PatientNotificationRules;
