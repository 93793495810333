import setStorageFiles from './setStorageFiles';
import setMoreStorageFiles from './setMoreStorageFiles';
import reset from './reset';
import deleteStorageFile from './deleteStorageFile';

export default {
  setStorageFiles,
  setMoreStorageFiles,
  reset,
  deleteStorageFile,
};
