import { useState, ReactElement, useCallback } from 'react';
import { Grid } from '@material-ui/core';
import Modal from '~/ui/components/common/Modal';
import DropStorageFilesContainer from './DropStorageFilesContainer';
import { IStorageFile, IStorageFilesSelectors } from '~/services/api/storageFiles/types';
import { StorageFileArea } from '~/services/api/enums';
import UploadedStorageFiles from './UploadedStorageFiles';

interface IProps {
  patientId: number;
  storageFileArea: StorageFileArea;
  selectors: IStorageFilesSelectors;
  onClose: () => void;
}

const UploadDocumentsModal = ({
  patientId,
  storageFileArea,
  selectors,
  onClose,
}: IProps): ReactElement => {
  const [storageFiles, setStorageFiles] = useState<IStorageFile[]>([]);

  const onStorageFileUploaded = (storageFile: IStorageFile) => {
    setStorageFiles(oldStorageFiles => [...oldStorageFiles, storageFile]);
  };
  const onStorageFileRemove = useCallback(
    (storageFile: IStorageFile) =>
      setStorageFiles(oldStorageFiles => oldStorageFiles.filter(x => x !== storageFile)),
    [setStorageFiles],
  );

  return (
    <Modal open title="Upload Documents" maxWidth="sm" fullWidth onClose={onClose}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DropStorageFilesContainer
            storageFileArea={storageFileArea}
            uploadedCount={storageFiles.length}
            storageFileTypes={selectors.storageFileTypes}
            onStorageFileUploaded={onStorageFileUploaded}
          />
        </Grid>
        <Grid item xs={12}>
          <UploadedStorageFiles
            patientId={patientId}
            storageFiles={storageFiles}
            documentTypes={selectors.documentTypes}
            onStorageFileRemove={onStorageFileRemove}
            onSubmitted={onClose}
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

export default UploadDocumentsModal;
