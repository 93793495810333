import { DeviceType } from '~/services/api/enums';
import { getDay, getDayPart } from '~/utils/deviceReading';
import {
  IBloodGlucoseReading,
  IBloodPressureReading,
  IPulseOximeterReading,
  IReading,
  IWeightScaleReading,
} from '~/services/api/readings/types';
import { DayPart, Day } from '~/types';
import { roundNumber } from '~/utils/format';
import { IDayPartReading, IDay, IAverageData, dayPartsList, daysList } from './types';

const getBloodPressureData = (readings: IBloodPressureReading[]): number[][] => [
  readings.map(x => x.systolic),
  readings.map(x => x.diastolic),
  readings.map(x => x.pulse),
];

const getWeightScaleData = (readings: IWeightScaleReading[]): number[][] => [
  readings.map(x => x.weightInLbs),
  readings.map(x => x.previousGainInLbs),
  readings.map(x => x.previousLossInLbs),
  readings.map(x => x.dailyGainInLbs),
  readings.map(x => x.dailyLossInLbs),
  readings.map(x => x.weeklyGainInLbs),
  readings.map(x => x.weeklyLossInLbs),
];

const getWeightScaleMorningEveningData = (readings: IWeightScaleReading[]): number[][] => [
  readings.map(x => x.weightInLbs),
];

const getPulseOximeterData = (readings: IPulseOximeterReading[]): number[][] => [
  readings.map(x => x.spo2),
  readings.map(x => x.pulseBpm),
];

const getBloodGlucoseData = (readings: IBloodGlucoseReading[]): number[][] => [
  readings.map(x => x.bloodGlucoseMgdl),
];

export const getRawData = (readings: IReading[], deviceTypeId: DeviceType): number[][] => {
  switch (deviceTypeId) {
    case DeviceType.BloodPressure:
      return getBloodPressureData(readings as IBloodPressureReading[]);
    case DeviceType.WeightScale:
      return getWeightScaleData(readings as IWeightScaleReading[]);
    case DeviceType.PulseOximeter:
      return getPulseOximeterData(readings as IPulseOximeterReading[]);
    case DeviceType.BloodGlucose:
      return getBloodGlucoseData(readings as IBloodGlucoseReading[]);
    default:
      throw new Error();
  }
};

export const getMorningEveningRawData = (
  readings: IReading[],
  deviceTypeId: DeviceType,
): number[][] => {
  switch (deviceTypeId) {
    case DeviceType.BloodPressure:
      return getBloodPressureData(readings as IBloodPressureReading[]);
    case DeviceType.WeightScale:
      return getWeightScaleMorningEveningData(readings as IWeightScaleReading[]);
    case DeviceType.PulseOximeter:
      return getPulseOximeterData(readings as IPulseOximeterReading[]);
    case DeviceType.BloodGlucose:
      return getBloodGlucoseData(readings as IBloodGlucoseReading[]);
    default:
      throw new Error();
  }
};

export const minByModule = (values: number[]): number =>
  values.every(x => x >= 0) ? Math.min(...values) : Math.max(...values);

export const maxByModule = (values: number[]): number =>
  values.every(x => x >= 0) ? Math.max(...values) : Math.min(...values);

export const sum = (values: number[]): number => values.reduce((s, x) => s + x, 0);

export const average = (values: number[]): number => sum(values) / Math.max(values.length, 1);

const getGroupedData = (dayPartReadings: IDayPartReading[], dayPart: DayPart, day: Day): IDay => {
  const readings = dayPartReadings.filter(x => x.dayPart === dayPart && x.day === day);

  if (readings.length === 0) {
    return {
      day,
      values: [],
      value: '(0)',
    };
  }

  const values = readings
    .map(x => [...x.values, ...x.additionalValues, x.batteryLevel, x.signalLevel])
    .reduce(
      (s, x) => x.map<[number, number]>((y, i) => [s[i][0] + y, s[i][1] + (y === null ? 0 : 1)]),
      Array<[number, number]>(
        readings[0].values.length + readings[0].additionalValues.length + 2,
      ).fill([0, 0]),
    )
    .map(([x, length]) => (length === 0 ? null : roundNumber(x / length)));

  return {
    day,
    values,
    value: `${values.map(x => (x === null ? '-' : x)).join('/')} (${readings.length})`,
  };
};

export const getAverageData = (dayPartReadings: IDayPartReading[]): IAverageData => ({
  dayParts: dayPartsList.map(dayPart => ({
    dayPart,
    days: daysList.map(day => getGroupedData(dayPartReadings, dayPart, day)),
  })),
});

const getBloodPressureValues = (reading: IBloodPressureReading): number[] => [
  reading.systolic,
  reading.diastolic,
  reading.pulse,
];

const getWeightScaleValues = (reading: IWeightScaleReading): number[] => [reading.weightInLbs];

const getPulseOximeterValues = (reading: IPulseOximeterReading): number[] => [
  reading.spo2,
  reading.pulseBpm,
];

const getBloodGlucoseValues = (reading: IBloodGlucoseReading): number[] => [
  reading.bloodGlucoseMgdl,
];

const getBloodPressureAdditionalValues = (reading: IBloodPressureReading): number[] => [
  reading.irregularHeartbeat ? 1 : 0,
];

const getWeightScaleAdditionalValues = (): number[] => [];

const getPulseOximeterAdditionalValues = (reading: IPulseOximeterReading): number[] => [
  reading.beforeMeal ? 1 : 0,
];

const getBloodGlucoseAdditionalValues = (reading: IBloodGlucoseReading): number[] => [
  reading.beforeMeal ? 1 : 0,
];

export const getDayPartReadings = (
  readings: IReading[],
  deviceTypeId: DeviceType,
): IDayPartReading[] => {
  let getValues: (reading: IReading) => number[];
  let getAdditionalValues: (reading: IReading) => number[];

  switch (deviceTypeId) {
    case DeviceType.BloodPressure:
      getValues = x => getBloodPressureValues(x as IBloodPressureReading);
      getAdditionalValues = x => getBloodPressureAdditionalValues(x as IBloodPressureReading);
      break;
    case DeviceType.WeightScale:
      getValues = x => getWeightScaleValues(x as IWeightScaleReading);
      getAdditionalValues = () => getWeightScaleAdditionalValues();
      break;
    case DeviceType.PulseOximeter:
      getValues = x => getPulseOximeterValues(x as IPulseOximeterReading);
      getAdditionalValues = x => getPulseOximeterAdditionalValues(x as IPulseOximeterReading);
      break;
    case DeviceType.BloodGlucose:
      getValues = x => getBloodGlucoseValues(x as IBloodGlucoseReading);
      getAdditionalValues = x => getBloodGlucoseAdditionalValues(x as IBloodGlucoseReading);
      break;
    default:
      throw new Error();
  }

  const data = readings.map(x => ({
    day: getDay(x.measuredAt),
    dayPart: getDayPart(x.measuredAt),
    batteryLevel: x.batteryLevel,
    signalLevel: x.signalLevel,
    values: getValues(x),
    additionalValues: getAdditionalValues(x),
  }));

  return data;
};

export const getYAxis = (deviceTypeId: DeviceType): Highcharts.YAxisOptions[] => {
  const defaultAxis = [
    {
      title: null,
    },
  ] as Highcharts.YAxisOptions[];

  const weightScaleAxis = [
    {
      title: null,
    },
    {
      title: null,
      opposite: true,
    },
  ] as Highcharts.YAxisOptions[];

  return deviceTypeId === DeviceType.WeightScale ? weightScaleAxis : defaultAxis;
};

export default {};
