import { ReactElement } from 'react';
import classnames from 'classnames';

import { formatNumber } from '~/utils/format';
import styles from './Card.module.scss';

interface IProps {
  colorType?: 'blue' | 'green' | 'purple' | 'red';
  iconComponent: any;
  amount: number;
  title: string;
  onClick: () => void;
}

const Card = ({
  colorType = 'blue',
  iconComponent: Icon,
  amount,
  title,
  onClick,
}: IProps): ReactElement => (
  <div role="presentation" className={styles.smallCard} onClick={onClick}>
    <div className={styles.inner}>
      <div className={classnames(styles.iconWrapper, styles[`iconWrapper_${colorType}`])}>
        <Icon className={classnames(styles.icon, styles[colorType])} />
      </div>
      <div className={styles.content}>
        <div className={classnames(styles.amount, styles[colorType])}>{formatNumber(amount)}</div>
        <div className={styles.title}>{title}</div>
      </div>
    </div>
  </div>
);

export default Card;
