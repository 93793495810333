import PrintIcon from '@mui/icons-material/Print';
import { Grid } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Checkbox, FormControl, FormControlLabel } from '@mui/material';

import Button from '~/ui/components/common/Button';
import styles from './Header.module.scss';
import api from '~/services/api';
import downloadFile from '~/utils/downloadFile';
import { extractErrorMessage } from '~/utils/error';
import { getFileNameFromContentDisposition } from '~/utils/headers';
import { useStoreActions } from '~/store/hooks';
import { IPagePermissions } from '../../types';
import { ICarePlan } from '~/services/api/carePlans/types';
import { isoToFormat } from '~/utils/date';
import ConfirmModal from '~/ui/components/common/ConfirmModal';

interface IProps {
  carePlan: ICarePlan;
  permissions: IPagePermissions;
}

const Header = ({ carePlan, permissions }: IProps) => {
  const [isGettingReport, setIsGettingReport] = useState(false);
  const [showReviewConfirm, setShowReviewConfirm] = useState(false);
  const [isReviewed, setIsReviewed] = useState(false);
  const [isReviewing, setIsReviewing] = useState(false);

  const { onReviewCarePlanByPatientId } = useStoreActions(actions => actions.carePlans);

  const { showError } = useStoreActions(actions => actions.snackbar);

  const onPrint = async () => {
    try {
      setIsGettingReport(true);
      const { data, headers } = await api.patients.getCarePlanReport(carePlan.patientId);
      const fileName = getFileNameFromContentDisposition(headers['content-disposition']);
      downloadFile(window.URL.createObjectURL(new Blob([data])), fileName);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsGettingReport(false);
    }
  };

  const onReview = () => {
    setIsReviewed(true);
    setShowReviewConfirm(true);
  };

  const handleReview = async () => {
    try {
      setIsReviewing(true);
      await onReviewCarePlanByPatientId(carePlan.patientId);
      setShowReviewConfirm(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsReviewing(false);
    }
  };

  const handleReviewConfirmClose = () => {
    setIsReviewed(false);
    setShowReviewConfirm(false);
  };

  useEffect(() => setIsReviewed(carePlan.reviewedByUserId !== null), [carePlan]);

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item className={styles.headerName}>
        Plan of Care
      </Grid>
      <Grid item xs>
        <FormControl variant="outlined">
          <FormControlLabel
            control={<Checkbox checked={isReviewed} color="primary" onChange={onReview} />}
            label={
              carePlan.reviewedByUserId === null
                ? 'Care Plan Oversight'
                : `Reviewed by ${carePlan.reviewedByUserFullName} on ${isoToFormat(
                    carePlan.reviewedAt,
                    "MMMM dd, yyyy, 'at' hh:mm aa",
                    carePlan.patientTimezone,
                  )}`
            }
            disabled={!permissions.canCarePlanOversight || carePlan.reviewedByUserId !== null}
          />
        </FormControl>
      </Grid>
      <Grid item>
        <Button isLoading={isGettingReport} disabled={isGettingReport} onClick={onPrint}>
          <PrintIcon className={styles.icon} />
          <span className={styles.text}>Print</span>
        </Button>
      </Grid>
      {showReviewConfirm && (
        <ConfirmModal
          isLoading={isReviewing}
          title="Care Plan Oversight"
          description="Are you sure you have reviewed the Plan of Care?"
          onClose={handleReviewConfirmClose}
          onConfirm={handleReview}
        />
      )}
    </Grid>
  );
};

export default Header;
