import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { getPaginationPayload } from '~/utils/pagination';
import { IStatusMobileUsersStore, IThunkGetMoreStatusMobileUsers } from '../types';

const onGetMoreStatusMobileUsers: IThunkGetMoreStatusMobileUsers = thunk(
  // @ts-ignore
  async ({ setMoreStatusMobileUsers }, payload, { getState }) => {
    const localState = getState() as IStatusMobileUsersStore;

    const paginationPayload = getPaginationPayload(localState.pagination);

    const { data } = await api.mobileUsers.getByStatus({
      ...payload,
      ...paginationPayload,
    });

    setMoreStatusMobileUsers(data);
  },
);

export default onGetMoreStatusMobileUsers;
