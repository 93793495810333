import { action } from 'easy-peasy';
import { IActionSetMoreStatusMobileUsers } from '../types';

const setMoreStatusMobileUsers: IActionSetMoreStatusMobileUsers = action((state, payload) => {
  const hasMore = state.pagination.itemsPerPage === payload.mobileUsers.length;

  state.statusMobileUsers.total = payload.total;
  state.statusMobileUsers.mobileUsers = [
    ...state.statusMobileUsers.mobileUsers,
    ...payload.mobileUsers,
  ];
  state.pagination.page += 1;
  state.pagination.hasMore = hasMore;
});

export default setMoreStatusMobileUsers;
