import { useEffect, useState, ReactElement } from 'react';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AccountTreeIcon from '@mui/icons-material/AccountTree';

import { useStoreState, useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import Loader from '~/ui/components/common/Loader';
import SmallCard from './cards/SmallCard';
import Card from './cards/Card';
import DevicesCard from './cards/DevicesCard';
import AddProgram from './popups/AddProgram';
import AddUser from './popups/AddUser';
import AddPatient from './popups/AddPatient';
import ActivePrograms from './popups/ActivePrograms';
import ActivePatients from './popups/ActivePatients';
import ApprovedPatients from './popups/ApprovedPatients';
import AdmittedPatients from './popups/AdmittedPatients';
import DischargedPatients from './popups/DischargedPatients';
import DevicesPopup from './popups/DevicesPopup';

import { UserRole, UserPermission } from '~/types';
import styles from './Dashboard.module.scss';
import { ReactComponent as ActiveUsersIcon } from './icons/ActiveUsersIcon.svg';
import { ReactComponent as InactiveUsersIcon } from './icons/InactiveUsersIcon.svg';
import { ReactComponent as ActiveMobileUsersIcon } from './icons/ActiveMobileUsersIcon.svg';
import { ReactComponent as InactiveMobileUsersIcon } from './icons/InactiveMobileUsersIcon.svg';
import { ReactComponent as ActivePatientsIcon } from './icons/ActivePatientsIcon.svg';
import { ReactComponent as ApprovedPatientsIcon } from './icons/ApprovedPatientsIcon.svg';
import { ReactComponent as AdmittedPatientsIcon } from './icons/AdmittedPatientsIcon.svg';
import { ReactComponent as DischargedPatientsIcon } from './icons/DischargedPatientsIcon.svg';
import ActiveUsers from './popups/ActiveUsers';
import InactiveUsers from './popups/InactiveUsers';
import ActiveMobileUsers from './popups/ActiveMobileUsers';
import InactiveMobileUsers from './popups/InactiveMobileUsers';

const Dashboard = (): ReactElement => {
  const [isLoading, setIsLoading] = useState(true);
  const [showAddProgram, setShowAddProgram] = useState(false);
  const [showAddUser, setShowAddUser] = useState(false);
  const [showAddPatient, setShowAddPatient] = useState(false);
  const [showActivePrograms, setShowActivePrograms] = useState(false);
  const [showActivePatients, setShowActivePatients] = useState(false);
  const [showApprovedPatients, setShowApprovedPatients] = useState(false);
  const [showAdmittedPatients, setShowAdmittedPatients] = useState(false);
  const [showDischargedPatients, setShowDischargedPatients] = useState(false);
  const [showActiveUsers, setShowActiveUsers] = useState(false);
  const [showInactiveUsers, setShowInactiveUsers] = useState(false);
  const [showActiveMobileUsers, setShowActiveMobileUsers] = useState(false);
  const [showInactiveMobileUsers, setShowInactiveMobileUsers] = useState(false);
  const [showDevicesPopup, setShowDevicesPopup] = useState(false);

  const userRole = useStoreState(state => state.auth.currentUser?.roleId);
  const userPermissions = useStoreState(state => state.auth.currentUser?.permissions || []);
  const counters = useStoreState(state => state.dashboard.counters);
  const onGetDashboardCounters = useStoreActions(
    actions => actions.dashboard.onGetDashboardCounters,
  );
  const showError = useStoreActions(actions => actions.snackbar.showError);

  const permissions = {
    canAddUsers:
      [UserRole.SuperAdmin, UserRole.SystemAdmin].includes(userRole) ||
      userPermissions.includes(UserPermission.ManageUsers),
    canAddProgram: [UserRole.SystemAdmin, UserRole.SuperAdmin].includes(userRole),
    canAddPatient:
      [UserRole.SuperAdmin, UserRole.SystemAdmin].includes(userRole) ||
      [UserPermission.ManagePatients, UserPermission.PatientsEnrollments].some(permission =>
        userPermissions.includes(permission),
      ),
    showProgramsBlock: [UserRole.ProgramAdmin, UserRole.SuperAdmin, UserRole.SystemAdmin].includes(
      userRole,
    ),
    showPatientsBlock:
      [UserRole.SuperAdmin, UserRole.SystemAdmin].includes(userRole) ||
      [UserPermission.ManagePatients, UserPermission.MonitorPatients].some(permission =>
        userPermissions.includes(permission),
      ),
    showUsersBlock:
      [UserRole.SuperAdmin, UserRole.SystemAdmin].includes(userRole) ||
      userPermissions.includes(UserPermission.ManageUsers),
    showMobileUsersBlock:
      [UserRole.SuperAdmin, UserRole.SystemAdmin].includes(userRole) ||
      userPermissions.includes(UserPermission.ManageMobileUsers),
    showDevicesBlock:
      [UserRole.SuperAdmin, UserRole.SystemAdmin].includes(userRole) ||
      userPermissions.includes(UserPermission.ManageDevices),
    showDevicesExtraBlock: [UserRole.SystemAdmin, UserRole.SuperAdmin].includes(userRole),
    canManagePatients:
      [UserRole.SuperAdmin, UserRole.SystemAdmin].includes(userRole) ||
      userPermissions.includes(UserPermission.ManagePatients),
  };

  const onMount = async () => {
    try {
      await onGetDashboardCounters();
      setIsLoading(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  if (isLoading) return <Loader />;

  return (
    <div className={styles.dashboard}>
      {(permissions.canAddUsers || permissions.canAddProgram || permissions.canAddPatient) && (
        <div className={styles.row}>
          <div className={styles.title}>Quick Actions</div>
          <div className={styles.content}>
            {permissions.canAddUsers && (
              <div className={styles.contentItem}>
                <SmallCard
                  colorType="blue"
                  iconComponent={GroupAddIcon}
                  title="Add User"
                  onClick={() => setShowAddUser(true)}
                />
              </div>
            )}
            {permissions.canAddProgram && (
              <div className={styles.contentItem}>
                <SmallCard
                  colorType="yellow"
                  iconComponent={AddCircleOutlineIcon}
                  title="Add Program"
                  onClick={() => setShowAddProgram(true)}
                />
              </div>
            )}
            {permissions.canAddPatient && (
              <div className={styles.contentItem}>
                <SmallCard
                  colorType="green"
                  iconComponent={GroupAddIcon}
                  title="Patient Enrollment"
                  onClick={() => setShowAddPatient(true)}
                />
              </div>
            )}
          </div>
        </div>
      )}
      {permissions.showProgramsBlock && (
        <div className={styles.row}>
          <div className={styles.title}>Programs</div>
          <div className={styles.content}>
            <div className={styles.contentItem}>
              <Card
                colorType="blue"
                iconComponent={AccountTreeIcon}
                amount={counters.activeProgramsCount}
                title="Active"
                onClick={() => setShowActivePrograms(true)}
              />
            </div>
          </div>
        </div>
      )}
      {(permissions.showUsersBlock || permissions.showMobileUsersBlock) && (
        <div className={styles.row}>
          <div className={styles.title}>
            {permissions.showUsersBlock && <div>Users</div>}
            {permissions.showMobileUsersBlock && <div>Mobile Users</div>}
          </div>
          <div className={styles.content}>
            {permissions.showUsersBlock && (
              <>
                <div className={styles.contentItem}>
                  <Card
                    colorType="green"
                    iconComponent={ActiveUsersIcon}
                    amount={counters.activeUsersCount}
                    title="Active"
                    onClick={() => setShowActiveUsers(true)}
                  />
                </div>
                <div className={styles.contentItem}>
                  <Card
                    colorType="red"
                    iconComponent={InactiveUsersIcon}
                    amount={counters.inactiveUsersCount}
                    title="Inactive"
                    onClick={() => setShowInactiveUsers(true)}
                  />
                </div>
              </>
            )}
            {permissions.showMobileUsersBlock && (
              <>
                <div className={styles.contentItem}>
                  <Card
                    colorType="green"
                    iconComponent={ActiveMobileUsersIcon}
                    amount={counters.activeMobileUsersCount}
                    title="Active"
                    onClick={() => setShowActiveMobileUsers(true)}
                  />
                </div>
                <div className={styles.contentItem}>
                  <Card
                    colorType="red"
                    iconComponent={InactiveMobileUsersIcon}
                    amount={counters.inactiveMobileUsersCount}
                    title="Inactive"
                    onClick={() => setShowInactiveMobileUsers(true)}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      )}
      {permissions.showPatientsBlock && (
        <div className={styles.row}>
          <div className={styles.title}>Patients</div>
          <div className={styles.content}>
            <div className={styles.contentItem}>
              <Card
                colorType="blue"
                iconComponent={ActivePatientsIcon}
                amount={counters.activePatientsCount}
                title="Active"
                onClick={() => setShowActivePatients(true)}
              />
            </div>
            <div className={styles.contentItem}>
              <Card
                colorType="green"
                iconComponent={ApprovedPatientsIcon}
                amount={counters.approvedPatientsCount}
                title="Approved"
                onClick={() => setShowApprovedPatients(true)}
              />
            </div>
            <div className={styles.contentItem}>
              <Card
                colorType="purple"
                iconComponent={AdmittedPatientsIcon}
                amount={counters.admittedPatientsCount}
                title="Admitted"
                onClick={() => setShowAdmittedPatients(true)}
              />
            </div>
            <div className={styles.contentItem}>
              <Card
                colorType="red"
                iconComponent={DischargedPatientsIcon}
                amount={counters.dischargedPatientsCount}
                title="Discharged"
                onClick={() => setShowDischargedPatients(true)}
              />
            </div>
          </div>
        </div>
      )}
      {permissions.showDevicesBlock && (
        <div className={styles.row}>
          <div className={styles.title}>Devices</div>
          <div className={styles.content}>
            <div className={styles.contentItem}>
              <DevicesCard
                counters={counters}
                permissions={permissions}
                onClick={() => setShowDevicesPopup(true)}
              />
            </div>
          </div>
        </div>
      )}
      {showAddProgram && <AddProgram onClose={() => setShowAddProgram(false)} />}
      {showAddUser && <AddUser onClose={() => setShowAddUser(false)} />}
      {showAddPatient && <AddPatient onClose={() => setShowAddPatient(false)} />}
      {showActivePrograms && <ActivePrograms onClose={() => setShowActivePrograms(false)} />}
      {showActiveUsers && <ActiveUsers onClose={() => setShowActiveUsers(false)} />}
      {showInactiveUsers && <InactiveUsers onClose={() => setShowInactiveUsers(false)} />}
      {showActiveMobileUsers && (
        <ActiveMobileUsers onClose={() => setShowActiveMobileUsers(false)} />
      )}
      {showInactiveMobileUsers && (
        <InactiveMobileUsers onClose={() => setShowInactiveMobileUsers(false)} />
      )}
      {showActivePatients && (
        <ActivePatients permissions={permissions} onClose={() => setShowActivePatients(false)} />
      )}
      {showApprovedPatients && (
        <ApprovedPatients
          permissions={permissions}
          onClose={() => setShowApprovedPatients(false)}
        />
      )}
      {showAdmittedPatients && (
        <AdmittedPatients
          permissions={permissions}
          onClose={() => setShowAdmittedPatients(false)}
        />
      )}
      {showDischargedPatients && (
        <DischargedPatients
          permissions={permissions}
          onClose={() => setShowDischargedPatients(false)}
        />
      )}
      {showDevicesPopup && <DevicesPopup onClose={() => setShowDevicesPopup(false)} />}
    </div>
  );
};

export default Dashboard;
