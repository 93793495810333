const buildFileFormData = (payloadFile: File, payloadName = 'file'): FormData => {
  const formData = new FormData();

  let file = payloadFile;

  if (file instanceof Blob) {
    file = new File([file], file.name, {
      type: file.type,
      lastModified: Date.now(),
    });
  }

  formData.append(payloadName, file);

  return formData;
};

export default buildFileFormData;
