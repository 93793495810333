import initStore from './initStore';
import actions from './actions';
import thunks from './thunks';

import { IStatusUsersModel } from './types';

const statusUsers: IStatusUsersModel = {
  ...initStore,
  ...actions,
  ...thunks,
};

export default statusUsers;
