import { Grid } from '@material-ui/core';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { ReactElement, useMemo } from 'react';
import { IPatientOverviewAverageTrend } from '~/services/api/patients/types';
import styles from './Trend.module.scss';

interface IProps {
  trend: IPatientOverviewAverageTrend;
}

const Trend = ({ trend }: IProps): ReactElement => {
  const className = useMemo(() => {
    if (trend.value > 0) {
      return styles.up;
    }

    if (trend.value < 0) {
      return styles.down;
    }

    return '';
  }, [trend]);

  return (
    <Grid container spacing={1} alignItems="center">
      {trend.value > 0 && (
        <Grid item className={className}>
          <ArrowUpwardIcon />
        </Grid>
      )}
      {trend.value < 0 && (
        <Grid item className={className}>
          <ArrowDownwardIcon />
        </Grid>
      )}
      <Grid item className={className}>
        {trend.value > 0 ? '+' : ''}
        {trend.value === null ? '-' : trend.value}
      </Grid>
      <Grid item>{trend.name}</Grid>
    </Grid>
  );
};

export default Trend;
