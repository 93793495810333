import { ReactElement, useEffect, useState } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Grid } from '@material-ui/core';
import { getChartOptions, getInitialEndDate, getInitialStartDate } from './helper';
import { DeviceType, PatientOverviewAverageType } from '~/services/api/enums';
import { IPatientOverview, IPatientOverviewAverage } from '~/services/api/patients/types';
import Loader from '~/ui/components/common/Loader';
import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import Table from './Table';
import styles from './Average.module.scss';
import MonthRangeDatePicker from '~/ui/components/inputs/MonthRangeDatePicker';
import WeekRangeDatePicker from '~/ui/components/inputs/WeekRangeDatePicker';
import Trend from './Trend';

interface IProps {
  type: PatientOverviewAverageType;
  deviceTypeId: DeviceType;
  patientOverview: IPatientOverview;
}

const Average = ({ type, deviceTypeId, patientOverview }: IProps): ReactElement => {
  const [isLoading, setIsLoading] = useState(true);
  const [startDate, setStartDate] = useState(getInitialStartDate(type));
  const [endDate, setEndDate] = useState(getInitialEndDate(type));
  const [patientOverviewAverage, setPatientOverviewAverage] = useState(
    null as IPatientOverviewAverage,
  );

  const onGetPatientOverviewAverage = useStoreActions(
    actions => actions.patient.onGetOverviewAverage,
  );
  const { showError } = useStoreActions(actions => actions.snackbar);

  const getData = async () => {
    try {
      setIsLoading(true);

      const payload = {
        patientId: patientOverview.id,
        params: {
          deviceTypeId,
          patientOverviewAverageType: type,
          startDate,
          endDate,
        },
      };

      const data = await onGetPatientOverviewAverage(payload);

      setPatientOverviewAverage(data);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [deviceTypeId, startDate, endDate, patientOverview]);

  const options = isLoading ? null : getChartOptions(patientOverviewAverage, deviceTypeId);

  return isLoading ? (
    <Loader />
  ) : (
    <Grid container>
      <Grid item xs={12}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item className={styles.title}>{`${type} Average`}</Grid>
          <Grid item>
            <Grid container spacing={3}>
              {patientOverviewAverage.trends.map(trend => (
                <Grid item key={trend.name}>
                  <Trend trend={trend} />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item>
            {type === PatientOverviewAverageType.Weekly ? (
              <WeekRangeDatePicker
                initialStartDate={startDate}
                initialEndDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            ) : (
              <MonthRangeDatePicker
                initialStartDate={startDate}
                initialEndDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {patientOverviewAverage.trends.every(trend => trend.value === null) ? (
          <Grid
            container
            className={styles.noDataContainer}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>No data in selected period</Grid>
          </Grid>
        ) : (
          <HighchartsReact highcharts={Highcharts} options={options} />
        )}
      </Grid>
      <Grid item xs={12}>
        <Table type={type} patientOverviewAverage={patientOverviewAverage} />
      </Grid>
    </Grid>
  );
};

export default Average;
