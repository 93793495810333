import { IStatusUsersStore } from './types';

const initStore: IStatusUsersStore = {
  statusUsers: {
    total: 0,
    users: [],
  },
  pagination: {
    page: 0,
    itemsPerPage: 20,
    hasMore: false,
  },
};

export default initStore;
