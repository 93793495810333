import { Grid } from '@material-ui/core';
import { ReactElement } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import { FormProvider, useForm } from 'react-hook-form';
import Button from '~/ui/components/common/Button';
import Input from '~/ui/components/inputs/Input';
import initialValues from './form/initialValues';
import validate from './form/validate';
import Select from '~/ui/components/inputs/Select';
import { HasAgreedOptions, StatusOptions } from './constants';
import { IMobileUsersListFilters } from '~/services/api/mobileUsers/types';
import { mobileUserRoleOptions } from '~/ui/constants/mobileUser';

interface IProps {
  onSubmit: (filters: IMobileUsersListFilters) => void;
}

const Header = ({ onSubmit }: IProps): ReactElement => {
  const formMethods = useForm<IMobileUsersListFilters>({
    resolver: validate,
    defaultValues: initialValues,
  });

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = formMethods;

  const handleOnSubmit = (values: IMobileUsersListFilters) => {
    const payload = { ...values };

    onSubmit(payload);
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Input name="phone" register={register} errors={errors} label="Mobile Number" />
          </Grid>
          <Grid item xs>
            <Select
              name="typeId"
              control={control}
              errors={errors}
              options={mobileUserRoleOptions}
              label="User Type"
            />
          </Grid>
          <Grid item xs>
            <Select
              name="isActive"
              control={control}
              errors={errors}
              options={StatusOptions}
              label="Status"
            />
          </Grid>
          <Grid item xs>
            <Select
              name="hasAgreed"
              control={control}
              errors={errors}
              options={HasAgreedOptions}
              label="T&C/HIPAA"
            />
          </Grid>
          <Grid item>
            <Button variant="outlined" type="submit" color="primary" startIcon={<SearchIcon />}>
              Search
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              type="button"
              startIcon={<RotateRightIcon />}
              onClick={() => {
                reset(initialValues);
                onSubmit(initialValues);
              }}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default Header;
