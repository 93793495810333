import { ReadingsMode } from '~/services/api/enums';

const readingsModeOptions = [
  {
    value: ReadingsMode.Historical,
    label: 'Historical',
  },
  {
    value: ReadingsMode.MailerVersion,
    label: 'Mailer Version',
  },
];

export default readingsModeOptions;
