import { ReactElement, useState } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { useStoreActions } from '~/store/hooks';
import { isoToFormat } from '~/utils/date';
import { extractErrorMessage } from '~/utils/error';
import ConfirmModal from '~/ui/components/common/ConfirmModal';
import Button from '~/ui/components/common/Button';
import NoDataFound from '~/ui/components/reusable/NoDataFound';

import { IProgramUser } from '~/services/api/users/types';
import styles from './AssociatedProgramsTable.module.scss';

interface IProps {
  currentUserId: number;
  programUsers: IProgramUser[];
  onRefresh: () => void;
}

const AssociatedProgramsTable = ({
  currentUserId,
  programUsers,
  onRefresh,
}: IProps): ReactElement => {
  const [openConfirm, setOpenConfirm] = useState(false);
  const [currentProgram, setCurrentProgram] = useState(null);

  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);
  const onRemovePrograms = useStoreActions(actions => actions.users.onRemovePrograms);

  const onRemoveProgramsConfirmed = async (userId: number, programId: number) => {
    const payload = { id: userId, programs: [programId] };
    try {
      await onRemovePrograms(payload);
      setOpenConfirm(false);
      onRefresh(); // refresh data in tables
      showNotify('Successfully unassigned from program');
    } catch (e) {
      showError(extractErrorMessage(e));
      setOpenConfirm(false);
    }
  };

  const openConfirmAndSetProgramId = (programId: number) => {
    setOpenConfirm(true);
    setCurrentProgram(programId);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Program Name</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {programUsers.length ? (
              programUsers.map(program => (
                <TableRow key={program.programId}>
                  <TableCell className={styles.cell}>{program.programName}</TableCell>
                  <TableCell>{isoToFormat(program.assignedAt, 'dd MMM y')}</TableCell>
                  <TableCell>
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={() => openConfirmAndSetProgramId(program.programId)}
                    >
                      UNASSIGN
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3}>
                  <NoDataFound />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {openConfirm && (
        <ConfirmModal
          onClose={() => setOpenConfirm(false)}
          onConfirm={() => onRemoveProgramsConfirmed(currentUserId, currentProgram)}
          title="Are you sure"
          description="Do you really want unasign current program?"
        />
      )}
    </>
  );
};

export default AssociatedProgramsTable;
