import { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useStoreState, useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import Icd10Codes from './components/Icd10Codes';
import Medications from './components/Medications';
import Hospitalizations from './components/Hospitalizations';
import Goals from './components/Goals';
import Loader from '~/ui/components/common/Loader';

import { UserRole, UserPermission } from '~/types';
import { IPagePermissions } from './types';
import styles from './PlanOfCare.module.scss';
import Interventions from './components/Interventions';
import Header from './components/Header';

type PatientParams = {
  id: string;
};

const PlanOfCare = (): ReactElement => {
  const routerParams = useParams<PatientParams>();
  const patientId = Number(routerParams.id);

  const [loading, setLoading] = useState(true);

  const userRole = useStoreState(state => state.auth.currentUser?.roleId);
  const userPermissions = useStoreState(state => state.auth.currentUser?.permissions || []);
  const patientInfo = useStoreState(state => state.patient.info);
  const carePlanIcd10Codes = useStoreState(state => state.carePlanIcd10Codes.items);
  const carePlanMedications = useStoreState(state => state.carePlanMedications.items);
  const carePlanHospitalizations = useStoreState(state => state.carePlanHospitalizations.items);
  const carePlanGoals = useStoreState(state => state.carePlanGoals.items);
  const carePlanInterventions = useStoreState(state => state.carePlanInterventions.items);
  const carePlan = useStoreState(state => state.carePlans.item);

  const { onGetCarePlanIcd10Codes } = useStoreActions(actions => actions.carePlanIcd10Codes);
  const { onGetCarePlanMedications } = useStoreActions(actions => actions.carePlanMedications);
  const { onGetCarePlanHospitalizations } = useStoreActions(
    actions => actions.carePlanHospitalizations,
  );
  const { onGetCarePlanGoals } = useStoreActions(actions => actions.carePlanGoals);
  const { onGetCarePlanInterventions } = useStoreActions(actions => actions.carePlanInterventions);
  const { onGetCarePlanByPatientId } = useStoreActions(actions => actions.carePlans);
  const { showError } = useStoreActions(actions => actions.snackbar);

  const permissions: IPagePermissions = {
    canManage:
      [UserRole.SuperAdmin, UserRole.SystemAdmin].includes(userRole) ||
      userPermissions.includes(UserPermission.ManagePatients),
    canCarePlanOversight:
      [UserRole.SuperAdmin, UserRole.SystemAdmin].includes(userRole) ||
      userPermissions.includes(UserPermission.CarePlanOversight),
  };

  const onMount = async () => {
    try {
      await Promise.all([
        onGetCarePlanIcd10Codes(patientId),
        onGetCarePlanMedications(patientId),
        onGetCarePlanHospitalizations(patientId),
        onGetCarePlanGoals(patientId),
        onGetCarePlanInterventions(patientId),
        onGetCarePlanByPatientId(patientId),
      ]);

      setLoading(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  const onCarePlanChange = async () => {
    try {
      await onGetCarePlanByPatientId(patientId);

      setLoading(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Header carePlan={carePlan} permissions={permissions} />
          <div className={styles.heightSpacer} />
          <Icd10Codes
            patientId={patientId}
            items={carePlanIcd10Codes}
            permissions={permissions}
            onCarePlanChange={onCarePlanChange}
          />
          <div className={styles.heightSpacer} />
          <Medications
            patientId={patientId}
            items={carePlanMedications}
            permissions={permissions}
            onCarePlanChange={onCarePlanChange}
          />
          <div className={styles.heightSpacer} />
          <Hospitalizations
            patientId={patientId}
            items={carePlanHospitalizations}
            permissions={permissions}
            isPatientSmoker={patientInfo.isSmoker}
            onCarePlanChange={onCarePlanChange}
          />
          <div className={styles.heightSpacer} />
          <Goals
            patientId={patientId}
            items={carePlanGoals}
            permissions={permissions}
            onCarePlanChange={onCarePlanChange}
          />
          <div className={styles.heightSpacer} />
          <Interventions
            patientId={patientId}
            items={carePlanInterventions}
            permissions={permissions}
            onCarePlanChange={onCarePlanChange}
          />
        </>
      )}
    </div>
  );
};

export default PlanOfCare;
