import { IStatusUsersFilters } from '~/services/api/users/types';

export const getInitialValues = () =>
  ({
    fullName: '',
    roleId: '',
    programId: '',
  } as IStatusUsersFilters);

export default {};
