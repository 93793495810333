import { getLastMonthDateRange } from '~/utils/date';
import { IFormValues } from '../types';
import { ReadingsMode } from '~/services/api/enums';

const getInitialValues = (): IFormValues => ({
  includeReadings: false,
  readingsMode: ReadingsMode.Historical,
  includeCharts: false,
  includeNotes: false,
  ...getLastMonthDateRange(),
});

export default getInitialValues;
