import { ReactElement } from 'react';
import UsersPopup from '../reusable/UsersPopup';

interface IProps {
  onClose: () => void;
}

const InactiveUsers = ({ onClose }: IProps): ReactElement => (
  <UsersPopup onClose={onClose} isActive={false} title="Inactive Users" />
);

export default InactiveUsers;
