import { ReactElement } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import MUITable from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import InfiniteScroll from 'react-infinite-scroll-component';
import { IPagination } from '~/utils/pagination';
import Loader from '~/ui/components/common/Loader';
import NoDataFound from '~/ui/components/reusable/NoDataFound';
import { IStatusUser } from '~/services/api/users/types';
import styles from './Table.module.scss';
import Item from './Item';

interface IProps {
  users: IStatusUser[];
  onLoadMore: () => void;
  pagination: IPagination;
}

const Table = ({ users, onLoadMore, pagination }: IProps): ReactElement => (
  <TableContainer className={styles.tableContainer} id="scrollableDiv">
    <InfiniteScroll
      next={onLoadMore}
      hasMore={pagination.hasMore}
      loader={<Loader />}
      dataLength={users.length}
      scrollableTarget="scrollableDiv"
    >
      <MUITable>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>Program</TableCell>
            <TableCell>Enrollment Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.length > 0 ? (
            users.map(user => <Item key={user.id} user={user} />)
          ) : (
            <TableRow>
              <TableCell colSpan={4}>
                <NoDataFound />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </MUITable>
    </InfiniteScroll>
  </TableContainer>
);

export default Table;
