import onGetPatient from './onGetPatient';
import onUpdatePatient from './onUpdatePatient';
import onDischargePatient from './onDischargePatient';
import onActivatePatient from './onActivatePatient';
import onGetOverview from './onGetOverview';
import onGetOverviewAverage from './onGetOverviewAverage';
import onGetInfo from './onGetInfo';
import onUpdateQuickNotes from './onUpdateQuickNotes';
import onUpdateIsSmoker from './onUpdateIsSmoker';

export default {
  onGetPatient,
  onGetOverview,
  onGetOverviewAverage,
  onGetInfo,
  onUpdatePatient,
  onDischargePatient,
  onActivatePatient,
  onUpdateQuickNotes,
  onUpdateIsSmoker,
};
