import { ReactElement } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import MUITable from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { IPatientOverviewAverage } from '~/services/api/patients/types';
import { intervalNames } from './constants';
import { PatientOverviewAverageType } from '~/services/api/enums';
import Item from './Item';

interface IProps {
  type: PatientOverviewAverageType;
  patientOverviewAverage: IPatientOverviewAverage;
}

const Table = ({ type, patientOverviewAverage }: IProps): ReactElement => (
  <TableContainer>
    <MUITable>
      <TableHead>
        <TableRow>
          <TableCell>{intervalNames[type]}</TableCell>
          {patientOverviewAverage.indicators.map(indicator => (
            <TableCell key={indicator.name} align="center">
              {indicator.name}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {patientOverviewAverage.intervals.map(interval => (
          <Item key={interval.label} interval={interval} />
        ))}
      </TableBody>
    </MUITable>
  </TableContainer>
);

export default Table;
