import initStore from './initStore';
import actions from './actions';
import thunks from './thunks';

import { IStorageFilesModel } from './types';

const storageFiles: IStorageFilesModel = {
  ...initStore,
  ...actions,
  ...thunks,
};

export default storageFiles;
