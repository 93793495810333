import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkCreateStorageFile } from '../types';

const onCreateStorageFile: IThunkCreateStorageFile = thunk(
  async (_, { storageFileArea, data, abortController, onUploadProgress }) =>
    api.storageFiles
      .create(storageFileArea, data, abortController, onUploadProgress)
      .then(result => result.data),
);

export default onCreateStorageFile;
