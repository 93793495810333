import { useEffect, useState, ReactElement } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Paper from '@material-ui/core/Paper';

import { useStoreState, useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import { getInitialValues } from './Header/form/getInitialValues';
import Loader from '~/ui/components/common/Loader';
import Header from './Header';
import Table from './Table';
import LogOfflineActivities from './popups/LogOfflineActivities/LogOfflineActivities';

import api from '~/services/api';
import { UserRole, UserPermission } from '~/types';
import { ISort } from '~/utils/sort/types';

import styles from './Patients.module.scss';
import { IPatientsListFilters } from '~/services/api/patients/types';
import InfoPanel from '~/ui/components/reusable/InfoPanel';
import { formatToIsoWithoutTimezone } from '~/utils/date';
import { TableCustomizationType } from '~/services/api/enums';
import { cells } from './Table/constants';
import EditTableModal from '~/ui/components/reusable/EditTableModal';

const Patients = (): ReactElement => {
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState<IPatientsListFilters>(getInitialValues());
  const [logActivitiesPatientId, setLogActivitiesPatientId] = useState(null);
  const [isEditingTable, setIsEditingTable] = useState(false);
  const [sort, setSort] = useState<ISort>({
    orderBy: 'maxNotificationLevel',
    isDescending: true,
  } as ISort);

  const userRole = useStoreState(state => state.auth.currentUser?.roleId);
  const userPermissions = useStoreState(state => state.auth.currentUser?.permissions || []);
  const { items: patients, pagination } = useStoreState(state => state.patients);
  const tableCustomizations = useStoreState(state => state.tableCustomizations.items)[
    TableCustomizationType.Patients
  ];
  const { onGetPatients, onGetMorePatients } = useStoreActions(actions => actions.patients);
  const { onGetTableCustomizationsByTypeId } = useStoreActions(
    actions => actions.tableCustomizations,
  );
  const showError = useStoreActions(actions => actions.snackbar.showError);

  const permissions = {
    canManagePatients:
      [UserRole.SuperAdmin, UserRole.SystemAdmin].includes(userRole) ||
      userPermissions.includes(UserPermission.ManagePatients),
    canManageReports:
      [UserRole.SuperAdmin, UserRole.SystemAdmin].includes(userRole) ||
      userPermissions.includes(UserPermission.ManageReports),
    canRemovePatient: userRole === UserRole.SystemAdmin,
  };

  const payload = {
    ...filters,
    ...sort,
    startDate: formatToIsoWithoutTimezone(filters.startDate),
    endDate: formatToIsoWithoutTimezone(filters.endDate),
  };

  const getTableCustomizations = async () => {
    try {
      await onGetTableCustomizationsByTypeId(TableCustomizationType.Patients);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const getPatients = async () => {
    setIsLoading(true);

    try {
      await onGetPatients(payload);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsLoading(false);
    }
  };

  const onLoadMorePatients = async () => {
    try {
      await onGetMorePatients(payload);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const removePatient = async (id: number) => {
    try {
      await api.patients.remove(id);
      await getPatients();
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  useEffect(() => {
    // getPatients() is called after component mount
    // and every time filters, sort props are changing
    getPatients();
  }, [filters, sort]);

  useEffect(() => {
    getTableCustomizations();
  }, []);

  const openLogActivitiesSetPatient = (patientId: number) => {
    setLogActivitiesPatientId(patientId);
  };

  const onEditTable = () => {
    setIsEditingTable(true);
  };

  return (
    <Paper elevation={3} className={styles.patients}>
      <Header onSubmit={setFilters} />
      <InfoPanel
        startDate={filters.startDate}
        endDate={filters.endDate}
        label="Patients"
        onEditTable={onEditTable}
      />
      <InfiniteScroll
        dataLength={patients.length}
        next={onLoadMorePatients}
        hasMore={pagination.hasMore}
        loader={<Loader />}
        className={styles.infiniteScroll}
      >
        <Table
          patients={patients}
          tableCustomizations={tableCustomizations}
          isLoading={isLoading}
          sort={sort}
          onSort={setSort}
          openLogActivitiesSetPatient={openLogActivitiesSetPatient}
          permissions={permissions}
          onRemove={removePatient}
        />
      </InfiniteScroll>
      {isLoading && <Loader />}
      {logActivitiesPatientId && (
        <LogOfflineActivities
          patientId={logActivitiesPatientId}
          onClose={() => setLogActivitiesPatientId(false)}
        />
      )}
      {isEditingTable && (
        <EditTableModal
          typeId={TableCustomizationType.Patients}
          title="Edit Table"
          cells={cells}
          onClose={() => setIsEditingTable(false)}
        />
      )}
    </Paper>
  );
};

export default Patients;
