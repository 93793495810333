import { ReactElement, useState } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import PhoneInput from 'react-phone-input-2';
import { IStatusMobileUser } from '~/services/api/mobileUsers/types';
import styles from './Item.module.scss';

interface IProps {
  mobileUser: IStatusMobileUser;
}

const Item = ({ mobileUser }: IProps): ReactElement => {
  const [formattedPhone, setFormattedPhone] = useState('');

  return (
    <TableRow>
      <TableCell>
        <div className={styles.phoneInputContainer}>
          <PhoneInput
            key={mobileUser.phone}
            country="us"
            regions={['north-america', 'europe']}
            value={mobileUser.phone}
            onMount={(_value, _data, formattedValue) => setFormattedPhone(formattedValue)}
          />
        </div>
        {formattedPhone}
      </TableCell>
      <TableCell>{mobileUser.roleName}</TableCell>
      <TableCell className={styles.cell}>
        {mobileUser.programs.map(x => x.name).join(', ')}
      </TableCell>
    </TableRow>
  );
};

export default Item;
