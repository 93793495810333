export const StatusOptions = [
  { value: '', label: 'All' },
  { value: true, label: 'Active' },
  { value: false, label: 'Inactive' },
];

export const HasAgreedOptions = [
  { value: '', label: 'All' },
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

export default {};
