import { IStatusMobileUsersFilters } from '~/services/api/mobileUsers/types';

export const getInitialValues = () =>
  ({
    phone: '',
    roleId: '',
    programId: '',
  } as IStatusMobileUsersFilters);

export default {};
