import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { getPaginationPayload } from '~/utils/pagination';
import { IStatusUsersStore, IThunkGetMoreStatusUsers } from '../types';

const onGetMoreStatusUsers: IThunkGetMoreStatusUsers = thunk(
  // @ts-ignore
  async ({ setMoreStatusUsers }, payload, { getState }) => {
    const localState = getState() as IStatusUsersStore;

    const paginationPayload = getPaginationPayload(localState.pagination);

    const { data } = await api.users.getByStatus({
      ...payload,
      ...paginationPayload,
    });

    setMoreStatusUsers(data);
  },
);

export default onGetMoreStatusUsers;
