import { ReactElement } from 'react';
import MobileUsersPopup from '../reusable/MobileUsersPopup';

interface IProps {
  onClose: () => void;
}

const InactiveMobileUsers = ({ onClose }: IProps): ReactElement => (
  <MobileUsersPopup onClose={onClose} isActive={false} title="Inactive Mobile Users" />
);

export default InactiveMobileUsers;
