import { ReactElement } from 'react';
import { Grid } from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import styles from './StorageFile.module.scss';
import { IStorageFile } from '~/services/api/storageFiles/types';
import Select from '~/ui/components/inputs/Select';
import { IOption } from '~/ui/components/inputs/Select/types';

interface IProps {
  storageFile: IStorageFile;
  index: number;
  documentTypes: IOption[];
  onRemove: () => void;
}

const StorageFileItem = ({ storageFile, index, documentTypes, onRemove }: IProps): ReactElement => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Grid container spacing={1}>
      <Grid item xs={4}>
        <Select
          name={`items.${index}.documentTypeId`}
          control={control}
          errors={errors}
          options={documentTypes}
          label="Document Type *"
        />
      </Grid>
      <Grid item xs={8}>
        <Grid
          container
          className={styles.container}
          alignItems="center"
          justifyContent="space-between"
          wrap="nowrap"
        >
          <Grid item className={styles.name}>
            {storageFile.name}
          </Grid>
          <Grid item>
            <IconButton color="secondary" title="Remove" onClick={onRemove}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StorageFileItem;
