import { action } from 'easy-peasy';
import { IActionSetMoreStatusUsers } from '../types';

const setMoreStatusUsers: IActionSetMoreStatusUsers = action((state, payload) => {
  const hasMore = state.pagination.itemsPerPage === payload.users.length;

  state.statusUsers.total = payload.total;
  state.statusUsers.users = [...state.statusUsers.users, ...payload.users];
  state.pagination.page += 1;
  state.pagination.hasMore = hasMore;
});

export default setMoreStatusUsers;
