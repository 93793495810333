import { ReactElement } from 'react';
import Grid from '@material-ui/core/Grid';

import { getChartsData } from './DaysReading/helper';
import DaysReadingCount from './DaysReadingCount';
import DaysReading from './DaysReading';

import { IReading } from '~/services/api/readings/types';
import styles from './WeeklyGraphs.module.scss';
import { DeviceType } from '~/services/api/enums';
import { getAverageData, getDayPartReadings } from '../helper';

interface IProps {
  readings: IReading[];
  deviceTypeId: DeviceType;
}

const WeeklyGraphs = ({ readings, deviceTypeId }: IProps): ReactElement => {
  const noData = readings.length === 0;

  const dayPartReadings = getDayPartReadings(readings, deviceTypeId);

  const averageData = getAverageData(dayPartReadings);

  const chartsData = getChartsData(averageData, deviceTypeId);

  return (
    <>
      <div className={styles.title}>Weekly Graphs</div>
      <Grid container spacing={3}>
        {chartsData.charts.map(chart => (
          <Grid item xs={6} key={chart.label}>
            <DaysReading noData={noData} title={chart.label} chartOptions={chart.options} />
          </Grid>
        ))}
        <Grid item xs={6}>
          <DaysReadingCount noData={noData} dayPartReadings={dayPartReadings} />
        </Grid>
      </Grid>
    </>
  );
};

export default WeeklyGraphs;
