import { ReactElement, useState, useEffect } from 'react';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import Header from './Header';
import Table from './Table';
import Modal from '~/ui/components/common/Modal';
import Loader from '~/ui/components/common/Loader';
import { IStatusUsersFilters } from '~/services/api/users/types';
import styles from './UsersPopup.module.scss';
import { getInitialValues } from './Header/form';

interface IProps {
  isActive: boolean;
  title: string;
  onClose: () => void;
}

const UsersPopup = ({ isActive, title, onClose }: IProps): ReactElement => {
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<IStatusUsersFilters>(getInitialValues());

  const { pagination, statusUsers } = useStoreState(state => state.statusUsers);
  const { onGetStatusUsers, onGetMoreStatusUsers } = useStoreActions(
    actions => actions.statusUsers,
  );
  const showError = useStoreActions(actions => actions.snackbar.showError);

  const payload = { isActive, ...filters };

  const onMount = async () => {
    setLoading(true);
    try {
      await onGetStatusUsers(payload);
      setLoading(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const onLoadMore = () => {
    onGetMoreStatusUsers(payload);
  };

  useEffect(() => {
    onMount();
  }, [filters]);

  return (
    <Modal open onClose={onClose} className={styles.modal} maxWidth="lg" title={title}>
      <div className={styles.container}>
        <Header isActive={isActive} total={statusUsers.total} onSubmit={setFilters} />
        {loading ? (
          <Loader />
        ) : (
          <Table users={statusUsers.users} onLoadMore={onLoadMore} pagination={pagination} />
        )}
      </div>
    </Modal>
  );
};

export default UsersPopup;
