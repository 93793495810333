import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkDeleteStorageFile } from '../types';

// @ts-ignore
const onDeleteStorageFile: IThunkDeleteStorageFile = thunk(async ({ deleteStorageFile }, id) => {
  await api.storageFiles.remove(id);
  deleteStorageFile(id);
});

export default onDeleteStorageFile;
