import { ReactElement } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Link } from 'react-router-dom';
import styles from './Item.module.scss';
import { IStatusPatient } from '~/services/api/patients/types';
import BloodGlucose from '~/ui/components/reusable/Reading/BloodGlucose';
import BloodPressure from '~/ui/components/reusable/Reading/BloodPressure';
import PulseOximeter from '~/ui/components/reusable/Reading/PulseOximeter';
import WeightScale from '~/ui/components/reusable/Reading/WeightScale';
import { PATIENT_DASHBOARD } from '~/ui/constants/paths';
import { IPagePermissions } from '~/ui/pages/Dashboard/types';
import { isoToFormat } from '~/utils/date';
import { PatientsPopupType } from '../../types';

interface IProps {
  patient: IStatusPatient;
  type: PatientsPopupType;
  permissions: IPagePermissions;
}

const Item = ({ patient, type, permissions }: IProps): ReactElement => (
  <TableRow>
    <TableCell className={styles.cell}>
      {permissions.canManagePatients ? (
        <Link to={PATIENT_DASHBOARD.replace(':id', String(patient.id))} className={styles.link}>
          {patient.fullName}
        </Link>
      ) : (
        patient.fullName
      )}
    </TableCell>
    {type !== PatientsPopupType.Active && <TableCell>{patient.subjectId}</TableCell>}
    {type === PatientsPopupType.Active && (
      <TableCell width={260}>
        <div className={styles.latestReadingWidgets}>
          {patient.latestReadings.bloodPressure && (
            <BloodPressure
              reading={patient.latestReadings.bloodPressure}
              rules={patient.notificationRules}
              timezone={patient.timezone}
            />
          )}
          {patient.latestReadings.weightScale && (
            <WeightScale
              reading={patient.latestReadings.weightScale}
              rules={patient.notificationRules}
              timezone={patient.timezone}
            />
          )}
          {patient.latestReadings.pulseOximeter && (
            <PulseOximeter
              reading={patient.latestReadings.pulseOximeter}
              rules={patient.notificationRules}
              timezone={patient.timezone}
            />
          )}
          {patient.latestReadings.bloodGlucose && (
            <BloodGlucose
              reading={patient.latestReadings.bloodGlucose}
              rules={patient.notificationRules}
              timezone={patient.timezone}
            />
          )}
        </div>
      </TableCell>
    )}
    <TableCell>{patient.programName}</TableCell>
    <TableCell>{isoToFormat(patient.createdAt, 'dd MMM y')}</TableCell>
    {type === PatientsPopupType.Discharged && (
      <TableCell>{isoToFormat(patient.dischargedAt, 'dd MMM y')}</TableCell>
    )}
  </TableRow>
);

export default Item;
