import { action } from 'easy-peasy';
import { IActionSetMoreStatusPatients } from '../types';

const setMoreStatusPatients: IActionSetMoreStatusPatients = action((state, payload) => {
  const hasMore = state.pagination.itemsPerPage === payload.patients.length;

  state.statusPatients.total = payload.total;
  state.statusPatients.patients = [...state.statusPatients.patients, ...payload.patients];
  state.pagination.page += 1;
  state.pagination.hasMore = hasMore;
});

export default setMoreStatusPatients;
