import { UserPermission, UserRole } from '~/types';

export const userTypeOptions = [
  {
    value: UserRole.HealthManager,
    label: 'Health Manager',
  },
  {
    value: UserRole.ProgramAdmin,
    label: 'Program Admin',
  },
  {
    value: UserRole.SuperAdmin,
    label: 'Super Admin',
  },
];

export const userRoleOptions = [{ value: '', label: 'All Users' }, ...userTypeOptions];

export const userPermissionOptions = [
  {
    value: UserPermission.ManageDevices,
    label: 'Manage Devices - Gives access to the Inventory tab. Can Assign/Unassign device',
  },
  {
    value: UserPermission.ManagePatients,
    label:
      'Manage Patients - Gives access to Patients tab with ability to go to patient level and Edit patients data. Also gives permission to discharge and reinstate patients',
  },
  {
    value: UserPermission.MonitorPatients,
    label:
      'Monitor Patients - Can only perform monitoring activities. Patients (limited access), Notification Rules (limited access) and Activity Logs. Users cannot go to the patient level to edit details',
  },
  {
    value: UserPermission.PatientsEnrollments,
    label: 'Patients Enrollments - Can enroll Patients',
  },
  {
    value: UserPermission.ManageReports,
    label: 'Manage Reports - Can view reports',
  },
  {
    value: UserPermission.ManageNotifications,
    label: 'Manage Notifications - Create/Terminate Notifications Rules for Patients',
  },
  {
    value: UserPermission.ManageUsers,
    label: 'Manage Users - Can view Users tab. Can Add/Edit details for Health Manager',
  },
  {
    value: UserPermission.ManageMobileUsers,
    label:
      'Manage Mobile Users - Can view the Mobile Users tab. Can manage the mobile application users',
  },
  {
    value: UserPermission.CarePlanOversight,
    label: 'Care Plan Oversight - Can check that the Plan of Care was reviewed',
  },
  {
    value: UserPermission.TimeAccrual,
    label: "Time Accrual - Track users' time spent on patients",
  },
];
