import { useEffect, useState, useMemo, ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import EventIcon from '@mui/icons-material/Event';
import RotateRightIcon from '@mui/icons-material/RotateRight';

import api from '~/services/api';
import { useStoreActions } from '~/store/hooks';
import {
  formatToIsoWithoutTimezone,
  getLastWeekDateRange,
  getLastMonthDateRange,
} from '~/utils/date';
import downloadFile from '~/utils/downloadFile';
import { getFileNameFromContentDisposition } from '~/utils/headers';
import { extractErrorMessage } from '~/utils/error';
import { getInitialValues, validate } from './form';
import Button from '~/ui/components/common/Button';
import SelectAll from '~/ui/components/inputs/SelectAll';
import DatePicker from '~/ui/components/inputs/DatePicker';

import { IPayrollReportRequest } from '~/services/api/reports/types';
import { IFormValues } from './types';
import { DateRangeType } from '../types';
import styles from './PayrollReport.module.scss';
import { IIdName } from '~/services/api/types';

const PayrollReport = (): ReactElement => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [healthManagers, setHealthManagers] = useState<IIdName[]>([]);
  const [areSelectorsLoading, setAreSelectorsLoading] = useState(true);

  const showError = useStoreActions(actions => actions.snackbar.showError);

  const initialValues = getInitialValues();

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<IFormValues>({
    resolver: validate,
    defaultValues: initialValues,
  });

  const healthManagerOptions = useMemo(
    () =>
      healthManagers.map(item => ({
        value: item.id,
        label: item.name,
      })),
    [healthManagers],
  );

  const generateReport = async (payload: IPayrollReportRequest) => {
    try {
      setIsProcessing(true);
      const { data, headers } = await api.reports.getPayrollReport(payload);
      const fileName = getFileNameFromContentDisposition(headers['content-disposition']);
      downloadFile(window.URL.createObjectURL(new Blob([data])), fileName);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsProcessing(false);
    }
  };

  const onSubmitForm = (values: IFormValues) => {
    generateReport({
      ...values,
      startDate: formatToIsoWithoutTimezone(values.startDate),
      endDate: formatToIsoWithoutTimezone(values.endDate),
    });
  };

  const resetDateRange = (type: DateRangeType) => {
    let dateRange = getLastWeekDateRange();

    if (type === DateRangeType.Month) {
      dateRange = getLastMonthDateRange();
    }

    setValue('startDate', dateRange.startDate);
    setValue('endDate', dateRange.endDate);
  };

  const onMount = async () => {
    try {
      const selectors = await api.reports.getPayrollSelectors().then(res => res.data);
      setHealthManagers(selectors.healthManagers);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setAreSelectorsLoading(false);
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <div className={styles.title}>Payroll Report</div>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <SelectAll
            name="healthManagerIds"
            control={control}
            errors={errors}
            options={healthManagerOptions}
            isLoading={areSelectorsLoading}
            label="Health Managers *"
          />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <DatePicker
            placeholder=".. /.. /.."
            label="Start Date *"
            name="startDate"
            maxDate={new Date()}
            control={control}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            placeholder=".. /.. /.."
            label="End Date *"
            name="endDate"
            maxDate={new Date()}
            control={control}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6}>
          <Button
            type="button"
            variant="outlined"
            color="primary"
            startIcon={<EventIcon />}
            onClick={() => resetDateRange(DateRangeType.Week)}
          >
            Last Week
          </Button>
          <div className={styles.spacer} />
          <Button
            type="button"
            variant="outlined"
            color="primary"
            startIcon={<EventIcon />}
            onClick={() => resetDateRange(DateRangeType.Month)}
          >
            Last Month
          </Button>
        </Grid>
        <Grid item xs={6} className={styles.rightBtnCol}>
          <Button
            type="button"
            variant="outlined"
            startIcon={<RotateRightIcon />}
            onClick={() => {
              reset(initialValues);
            }}
          >
            Reset
          </Button>
          <div className={styles.spacer} />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            isLoading={isProcessing}
            disabled={isProcessing}
          >
            Generate
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default PayrollReport;
