import * as Highcharts from 'highcharts';
import {
  endOfMonth,
  endOfWeek,
  formatISO,
  startOfMonth,
  startOfWeek,
  subMonths,
  subWeeks,
} from 'date-fns';
import { DeviceType, PatientOverviewAverageType } from '~/services/api/enums';
import { getYAxis } from '../helper';
import { IPatientOverviewAverage } from '~/services/api/patients/types';

const getSeries = (
  patientOverviewAverage: IPatientOverviewAverage,
): Highcharts.SeriesColumnOptions[] =>
  patientOverviewAverage.indicators.map((indicator, i) => ({
    name: indicator.name,
    type: 'column',
    data: patientOverviewAverage.intervals.map(x => x.indicators[i].value),
    color: indicator.color,
  }));

export const getChartOptions = (
  patientOverviewAverage: IPatientOverviewAverage,
  deviceTypeId: DeviceType,
): Highcharts.Options => ({
  chart: {
    type: 'column',
    zoomType: 'x',
    marginTop: 25,
  },
  title: {
    text: null,
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    categories: patientOverviewAverage.intervals.map(x => x.label),
  },
  yAxis: getYAxis(deviceTypeId),
  plotOptions: {
    column: {
      dataLabels: {
        enabled: true,
        crop: false,
      },
    },
  },
  series: getSeries(patientOverviewAverage),
});

export const getInitialStartDate = (patientOverviewAverageType: PatientOverviewAverageType) => {
  const now = new Date();

  const date =
    patientOverviewAverageType === PatientOverviewAverageType.Weekly
      ? subWeeks(startOfWeek(now, { weekStartsOn: 1 }), 3)
      : subMonths(startOfMonth(now), 2);

  return formatISO(date, { representation: 'date' });
};

export const getInitialEndDate = (patientOverviewAverageType: PatientOverviewAverageType) => {
  const now = new Date();

  const date =
    patientOverviewAverageType === PatientOverviewAverageType.Weekly
      ? endOfWeek(now, { weekStartsOn: 1 })
      : endOfMonth(now);

  return formatISO(date, { representation: 'date' });
};

export default {};
