import { useState, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import NotesIcon from '@mui/icons-material/Notes';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import AssessmentIcon from '@mui/icons-material/Assessment';
import HistoryIcon from '@mui/icons-material/History';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Popover } from '@mui/material';

import { PATIENT_DASHBOARD, PATIENT_REPORTS } from '~/ui/constants/paths';
import { IPatientList } from '~/services/api/patients/types';
import { IPagePermissions } from '../../../types';
import styles from './InfoMenu.module.scss';
import { PatientStatus } from '~/services/api/enums';

interface IProps {
  permissions: IPagePermissions;
  patient: IPatientList;
  onShowNotesPopup: () => void;
  onShowActivatePopup: () => void;
  onShowDeletePopup: () => void;
  openLogActivitiesSetPatient: () => void;
}

const InfoMenu = ({
  permissions,
  patient,
  onShowNotesPopup,
  onShowActivatePopup,
  onShowDeletePopup,
  openLogActivitiesSetPatient,
}: IProps): ReactElement => {
  const [open, setOpen] = useState(false);
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null);

  const onOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
    setOpen(true);
  };

  const onClose = () => {
    setAnchorElement(null);
    setOpen(false);
  };

  const handleShowNotesPopup = () => {
    setOpen(false);
    onShowNotesPopup();
  };

  const handleShowOfflineActivitiesPopup = () => {
    setOpen(false);
    openLogActivitiesSetPatient();
  };

  const handleShowActivatePopup = () => {
    setOpen(false);
    onShowActivatePopup();
  };

  const handleShowDeletePopup = () => {
    setOpen(false);
    onShowDeletePopup();
  };

  return (
    <div>
      <IconButton className={styles.expandIcon} onClick={onOpen} title="Info">
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorElement}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <ul className={styles.menu}>
          <li>
            <div role="presentation" className={styles.link} onClick={handleShowNotesPopup}>
              <NotesIcon className={styles.icon} />
              Notes
            </div>
          </li>
          {permissions.canManageReports && (
            <li>
              <Link to={PATIENT_REPORTS.replace(':id', String(patient.id))} className={styles.link}>
                <InsertDriveFileIcon className={styles.icon} />
                Report
              </Link>
            </li>
          )}
          {permissions.canManagePatients && (
            <li>
              <Link
                to={PATIENT_DASHBOARD.replace(':id', String(patient.id))}
                className={styles.link}
              >
                <AssessmentIcon className={styles.icon} />
                Graphs
              </Link>
            </li>
          )}
          <li>
            <div
              role="presentation"
              className={styles.link}
              onClick={handleShowOfflineActivitiesPopup}
            >
              <HistoryIcon className={styles.icon} />
              Log Offline Activities
            </div>
          </li>
          {permissions.canManagePatients && patient.status === PatientStatus.Discharged && (
            <li>
              <div role="presentation" className={styles.link} onClick={handleShowActivatePopup}>
                <AddCircleIcon className={styles.icon} />
                Activate Patient
              </div>
            </li>
          )}
          {permissions.canRemovePatient && patient.status === PatientStatus.Discharged && (
            <li>
              <div role="presentation" className={styles.link} onClick={handleShowDeletePopup}>
                <RemoveCircleIcon className={styles.icon} />
                Delete Patient
              </div>
            </li>
          )}
        </ul>
      </Popover>
    </div>
  );
};

export default InfoMenu;
