import { ReactElement } from 'react';
import PatientsPopup from '../reusable/PatientsPopup';
import { IPagePermissions } from '../../types';
import { PatientsPopupType } from '../reusable/PatientsPopup/types';

interface IProps {
  permissions: IPagePermissions;
  onClose: () => void;
}

const AdmittedPatients = ({ permissions, onClose }: IProps): ReactElement => (
  <PatientsPopup
    type={PatientsPopupType.Admitted}
    permissions={permissions}
    onClose={onClose}
    status={null}
    title="Admitted Patients"
  />
);

export default AdmittedPatients;
