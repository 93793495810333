import { ReactElement } from 'react';
import PatientsPopup from '../reusable/PatientsPopup';
import { IPagePermissions } from '../../types';
import { PatientStatus } from '~/services/api/enums';
import { PatientsPopupType } from '../reusable/PatientsPopup/types';

interface IProps {
  permissions: IPagePermissions;
  onClose: () => void;
}

const ActivePatients = ({ permissions, onClose }: IProps): ReactElement => (
  <PatientsPopup
    type={PatientsPopupType.Active}
    permissions={permissions}
    status={PatientStatus.Active}
    title="Active Patients"
    onClose={onClose}
  />
);

export default ActivePatients;
