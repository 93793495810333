import { ReactElement } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import MUITable from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import InfiniteScroll from 'react-infinite-scroll-component';
import { IPagination } from '~/utils/pagination';
import Loader from '~/ui/components/common/Loader';
import NoDataFound from '~/ui/components/reusable/NoDataFound';
import { IStatusPatient } from '~/services/api/patients/types';
import { IPagePermissions } from '../../../../types';
import styles from './Table.module.scss';
import { PatientsPopupType } from '../types';
import Item from './Item';

const colSpan = {
  [PatientsPopupType.Active]: 4,
  [PatientsPopupType.Approved]: 4,
  [PatientsPopupType.Admitted]: 4,
  [PatientsPopupType.Discharged]: 5,
};

interface IProps {
  type: PatientsPopupType;
  patients: IStatusPatient[];
  permissions: IPagePermissions;
  onLoadMore: () => void;
  pagination: IPagination;
}

const Table = ({ type, patients, permissions, onLoadMore, pagination }: IProps): ReactElement => (
  <TableContainer className={styles.tableContainer} id="scrollableDiv">
    <InfiniteScroll
      next={onLoadMore}
      hasMore={pagination.hasMore}
      loader={<Loader />}
      dataLength={patients.length}
      scrollableTarget="scrollableDiv"
    >
      <MUITable>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            {type !== PatientsPopupType.Active && <TableCell>Patient ID</TableCell>}
            {type === PatientsPopupType.Active && <TableCell>Latest Reading</TableCell>}
            <TableCell>Program</TableCell>
            <TableCell>Enrollment Date</TableCell>
            {type === PatientsPopupType.Discharged && <TableCell>Discharge Date</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {patients.length > 0 ? (
            patients.map(patient => (
              <Item key={patient.id} patient={patient} type={type} permissions={permissions} />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={colSpan[type]}>
                <NoDataFound />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </MUITable>
    </InfiniteScroll>
  </TableContainer>
);

export default Table;
