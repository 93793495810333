import api from '~/services/api';
import { getRefreshToken, getClientId, deleteTokens } from '~/services/auth';
import { IThunkLogout, IAuthModel } from '../types';
import { thunk } from '~/store/types';

const onLogout = thunk<IAuthModel, IThunkLogout>(
  async ({ reset, setAuthorized }, _, { getStoreActions }) => {
    try {
      const refreshToken = getRefreshToken();
      const clientId = getClientId();

      await api.auth.logOut({
        refreshToken,
        clientId,
      });

      getStoreActions().snackbar.hide();
    } catch {
      deleteTokens();
    } finally {
      reset();
      setAuthorized();
    }
  },
);

export default onLogout;
