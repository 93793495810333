import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetOverviewAverage } from '../types';

const onGetOverviewAverage: IThunkGetOverviewAverage = thunk(
  // @ts-ignore
  async (_, payload) => {
    const { patientId, params } = payload;

    const patientOverviewAverage = await api.patients
      .getOverviewAverage(patientId, params)
      .then(res => res.data);

    return patientOverviewAverage;
  },
);

export default onGetOverviewAverage;
