import axios from 'axios';
import { IResponse } from '~/services/api/types';
import {
  IUser,
  IUserList,
  IProgramUser,
  IUserPatient,
  IGetUsersPatientsParams,
  IUserSelectors,
  IUpdateCurrentUser,
  IUpdateCurrentUserPassword,
  IStatusUsers,
  IStatusUsersFilters,
  IUsersSelectors,
  IUsersListFilters,
} from './types';

const apiUrl = 'users';

export const updateCurrent = (data: IUpdateCurrentUser): IResponse<IUser> =>
  axios({
    method: 'put',
    url: `${apiUrl}/current`,
    data,
  });

export const updateCurrentPassword = (data: IUpdateCurrentUserPassword): IResponse<IUser> =>
  axios({
    method: 'put',
    url: `${apiUrl}/current/password`,
    data,
  });

export const get = (id: number): IResponse<IUser> =>
  axios({
    method: 'get',
    url: `${apiUrl}/${id}`,
  });

export const create = (data: IUser): IResponse<IUser> =>
  axios({
    method: 'post',
    url: apiUrl,
    data,
  });

export const update = (data: IUser): IResponse<IUser> =>
  axios({
    method: 'put',
    url: apiUrl,
    data,
  });

export const remove = (id: number): IResponse<void> =>
  axios({
    method: 'delete',
    url: `${apiUrl}/${id}`,
  });

export const getList = (params?: IUsersListFilters): IResponse<IUserList[]> =>
  axios({
    method: 'get',
    url: apiUrl,
    params,
  });

export const getByStatus = (params: IStatusUsersFilters): IResponse<IStatusUsers> =>
  axios({
    method: 'get',
    url: `${apiUrl}/by-status`,
    params,
  });

export const getAssociatedPrograms = (userId: number): IResponse<IProgramUser[]> =>
  axios({
    method: 'get',
    url: `${apiUrl}/${userId}/programs`,
  });

export const removePrograms = (userId: number, programs: number[]): IResponse<void> =>
  axios({
    method: 'delete',
    url: `${apiUrl}/${userId}/programs`,
    data: programs,
  });

export const addPrograms = (userId: number, programs: number[]): IResponse<void> =>
  axios({
    method: 'post',
    url: `${apiUrl}/${userId}/programs`,
    data: programs,
  });

export const addPatients = (userId: number, patients: number[]): IResponse<void> =>
  axios({
    method: 'post',
    url: `${apiUrl}/${userId}/patients`,
    data: patients,
  });

export const getPatients = (
  userId: number,
  params: IGetUsersPatientsParams,
): IResponse<IUserPatient[]> =>
  axios({
    method: 'get',
    url: `${apiUrl}/${userId}/patients`,
    params,
  });

export const removePatients = (userId: number, patients: number[]): IResponse<void> =>
  axios({
    method: 'delete',
    url: `${apiUrl}/${userId}/patients`,
    data: patients,
  });

export const getSelectors = (): IResponse<IUsersSelectors> =>
  axios({
    method: 'get',
    url: `${apiUrl}/selectors`,
  });

export const getSelectorsById = (id: number): IResponse<IUserSelectors> =>
  axios({
    method: 'get',
    url: `${apiUrl}/${id}/selectors`,
  });
