import { action } from 'easy-peasy';
import initStore from '../initStore';
import { IActionReset } from '../types';

const reset: IActionReset = action(state => {
  const { statusMobileUsers, pagination } = initStore;

  state.statusMobileUsers = statusMobileUsers;
  state.pagination = pagination;
});

export default reset;
