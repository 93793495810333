import { IStatusPatientsStore } from './types';

const initStore: IStatusPatientsStore = {
  statusPatients: {
    total: 0,
    patients: [],
  },
  pagination: {
    page: 0,
    itemsPerPage: 20,
    hasMore: false,
  },
};

export default initStore;
