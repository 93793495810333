import {
  ChangeEvent,
  DragEvent,
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Grid } from '@material-ui/core';
import classnames from 'classnames';
import { IStorageFile } from '~/services/api/storageFiles/types';
import styles from './DropStorageFilesContainer.module.scss';
import { IStorageFileItem } from './types';
import { IIdName } from '~/services/api/types';
import { ReactComponent as UploadIcon } from './UploadIcon.svg';
import StorageFileItem from './StorageFileItem';
import { StorageFileArea } from '~/services/api/enums';

const maxFileSizeMb = 50;
const maxFileSize = maxFileSizeMb * 1024 * 1024;

interface IProps {
  storageFileArea: StorageFileArea;
  uploadedCount: number;
  storageFileTypes: IIdName[];
  onStorageFileUploaded: (storageFile: IStorageFile) => void;
}

const DropStorageFilesContainer = ({
  storageFileArea,
  uploadedCount,
  storageFileTypes,
  onStorageFileUploaded,
}: IProps): ReactElement => {
  const [storageFileItems, setStorageFileItems] = useState<IStorageFileItem[]>([]);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const accept = useMemo(
    () => storageFileTypes.map(x => `.${x.name}`).join(','),
    [storageFileTypes],
  );

  const supportedFormats = useMemo(
    () => storageFileTypes.map(x => x.name).join(', '),
    [storageFileTypes],
  );

  const onInput = useCallback(
    (files: FileList) => {
      const newStorageFileItems = [
        ...storageFileItems,
        ...Array.from(files ?? [])
          .filter(
            x =>
              x.size <= maxFileSize &&
              storageFileTypes.some(y => x.name.toLowerCase().endsWith(y.name.toLowerCase())),
          )
          .map(x => ({
            name: x.name,
            file: x,
          })),
      ];

      setStorageFileItems(newStorageFileItems);
    },
    [storageFileItems, setStorageFileItems, storageFileTypes],
  );

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();

      onInput(event.target.files);
    },
    [onInput],
  );

  const onDrop = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      onInput(event.dataTransfer.files);
    },
    [onInput],
  );

  const onDragOver = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  }, []);

  const onFileInputClick = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
      fileInputRef.current.click();
    }
  }, [fileInputRef]);

  const onCancel = useCallback(
    (storageFileItem: IStorageFileItem) => {
      setStorageFileItems(oldStorageFileItems =>
        oldStorageFileItems.filter(x => x !== storageFileItem),
      );
    },
    [setStorageFileItems],
  );

  const onUploaded = useCallback(
    (storageFileItem: IStorageFileItem, storageFile: IStorageFile) => {
      setStorageFileItems(oldStorageFileItems =>
        oldStorageFileItems.filter(x => x !== storageFileItem),
      );

      onStorageFileUploaded(storageFile);
    },
    [setStorageFileItems, onStorageFileUploaded],
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <input
          className={styles.input}
          type="file"
          accept={accept}
          multiple
          ref={fileInputRef}
          onChange={onChange}
        />
        <Grid container className={styles.dropContainer} onDragOver={onDragOver} onDrop={onDrop}>
          <Grid item xs={12}>
            <Grid
              container
              className={classnames(styles.dropContainerContent, {
                [styles.small]: uploadedCount > 0,
              })}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <UploadIcon />
              </Grid>
              <Grid item className={styles.callToAction}>
                Drag & drop files or&nbsp;
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <span className={styles.browse} onClick={onFileInputClick}>
                  Browse
                </span>
              </Grid>
              <Grid item className={styles.limitations}>
                <Grid>Supported formats: {supportedFormats}</Grid>
                <Grid>Size limitation: {maxFileSizeMb}MB per file.</Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {storageFileItems.length > 0 && (
        <>
          <Grid item className={styles.uploading} xs={12}>
            Uploading - {storageFileItems.length}/{storageFileItems.length + uploadedCount} files
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              {storageFileItems.map((storageFileItem, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Grid item xs={12} key={index}>
                  <StorageFileItem
                    storageFileArea={storageFileArea}
                    storageFileItem={storageFileItem}
                    onCancel={() => onCancel(storageFileItem)}
                    onUploaded={storageFile => onUploaded(storageFileItem, storageFile)}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default DropStorageFilesContainer;
