import { useEffect, useState, ReactElement } from 'react';
import Modal from '~/ui/components/common/Modal';

import { useStoreState, useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import Loader from '~/ui/components/common/Loader';
import ProgramForm from '~/ui/components/reusable/ProgramForm';

import { IProgram } from '~/services/api/programs/types';
import styles from './EditProgram.module.scss';

interface IProps {
  programId: number;
  onClose: () => void;
}

const EditProgram = ({ programId, onClose }: IProps): ReactElement => {
  const [isLoading, setIsLoading] = useState(true);
  const [isProgramUpdating, setIsProgramUpdating] = useState(false);

  const program = useStoreState(state => state.programs.item);
  const { onGetProgram, onUpdateProgram } = useStoreActions(actions => actions.programs);
  const { showNotify, showError } = useStoreActions(actions => actions.snackbar);

  const updateProgram = async (data: IProgram) => {
    setIsProgramUpdating(true);

    try {
      const payload = { ...data, id: programId };

      await onUpdateProgram(payload);

      showNotify('Program successfully updated');
      onClose();
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsProgramUpdating(false);
    }
  };

  const handleClose = () => {
    // prevent closing popup when program is updating
    if (isProgramUpdating) return;
    onClose();
  };

  const onMount = async () => {
    try {
      await onGetProgram(programId);
      setIsLoading(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  return (
    <Modal open title="Update Program" className={styles.modal} onClose={handleClose}>
      {isLoading ? (
        <Loader />
      ) : (
        <ProgramForm
          initialValues={program}
          isProcessing={isProgramUpdating}
          onSubmit={updateProgram}
        />
      )}
    </Modal>
  );
};

export default EditProgram;
