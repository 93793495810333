import { useState, ReactElement } from 'react';
import Modal from '~/ui/components/common/Modal';

import api from '~/services/api';
import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import initialValues from './form/initialValues';
import ProgramForm from '~/ui/components/reusable/ProgramForm';

import { IProgram } from '~/services/api/programs/types';
import styles from './AddProgram.module.scss';

interface IProps {
  onClose: () => void;
}

const AddProgram = ({ onClose }: IProps): ReactElement => {
  const [isProgramAdding, setIsProgramAdding] = useState(false);

  const onGetDashboardCounters = useStoreActions(
    actions => actions.dashboard.onGetDashboardCounters,
  );
  const { showNotify, showError } = useStoreActions(actions => actions.snackbar);

  const addProgram = async (data: IProgram) => {
    setIsProgramAdding(true);

    try {
      await api.programs.create(data);
      onGetDashboardCounters();

      showNotify('Program successfully added');
      onClose();
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsProgramAdding(false);
    }
  };

  const handleClose = () => {
    // prevent closing popup when program is adding
    if (isProgramAdding) return;
    onClose();
  };

  return (
    <Modal open title="Add Program" className={styles.modal} onClose={handleClose}>
      <ProgramForm
        initialValues={initialValues}
        isProcessing={isProgramAdding}
        onSubmit={addProgram}
      />
    </Modal>
  );
};

export default AddProgram;
