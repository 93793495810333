import { ReactElement } from 'react';
import PatientsPopup from '../reusable/PatientsPopup';
import { IPagePermissions } from '../../types';
import { PatientStatus } from '~/services/api/enums';
import { PatientsPopupType } from '../reusable/PatientsPopup/types';

interface IProps {
  permissions: IPagePermissions;
  onClose: () => void;
}

const ApprovedPatients = ({ permissions, onClose }: IProps): ReactElement => (
  <PatientsPopup
    type={PatientsPopupType.Approved}
    permissions={permissions}
    onClose={onClose}
    status={PatientStatus.Approved}
    title="Approved Patients"
  />
);

export default ApprovedPatients;
