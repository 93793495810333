import { useMemo } from 'react';
import { ICurrentUser } from '~/services/api/auth/types';
import { UserRole, UserPermission } from '~/types';

export const useHasAccess = (
  currentUser: ICurrentUser,
  roles: UserRole[],
  permissions: UserPermission[],
) =>
  useMemo(() => {
    if (currentUser === null) {
      return false;
    }

    if (currentUser.roleId === UserRole.SystemAdmin) {
      return true;
    }

    if (roles?.includes(currentUser.roleId)) {
      return true;
    }

    if (permissions?.some(x => currentUser.permissions.includes(x))) {
      return true;
    }

    if (
      currentUser.roleId !== UserRole.HealthManager &&
      currentUser.roleId !== UserRole.ProgramAdmin &&
      (roles?.length ?? 0) === 0
    ) {
      return true;
    }

    if (
      (currentUser.roleId === UserRole.HealthManager ||
        currentUser.roleId === UserRole.ProgramAdmin) &&
      (permissions?.length ?? 0) === 0 &&
      (roles?.length ?? 0) === 0
    ) {
      return true;
    }

    return false;
  }, [currentUser, roles, permissions]);

export default {};
