export enum InsuranceType {
  Primary = 'Primary',
  Secondary = 'Secondary',
}

export enum PhysicianType {
  Attending = 'Attending',
  Secondary = 'Secondary',
}

export enum InsurancePlan {
  Family = 'Family',
  Individual = 'Individual',
}

export enum InsurancePlanType {
  PPOBronze = 'PPOBronze',
  PPOSilver = 'PPOSilver',
  PPOGold = 'PPOGold',
  HMO = 'HMO',
  Medicare = 'Medicare',
  Medicaid = 'Medicaid',
}

export enum ActivityLogMode {
  Online = 'Online',
  Offline = 'Offline',
}

export enum DeviceStatus {
  Available = 'Available',
  Ready = 'Ready',
  Issued = 'Issued',
  Returning = 'Returning',
  Returned = 'Returned',
  Lost = 'Lost',
}

export enum DeviceType {
  BloodPressure = 'BloodPressure',
  WeightScale = 'WeightScale',
  PulseOximeter = 'PulseOximeter',
  BloodGlucose = 'BloodGlucose',
}

export enum DeviceSupplier {
  Transtek = 'Transtek',
  BodyTrace = 'BodyTrace',
  SmartMeter = 'SmartMeter',
  Transtek2 = 'Transtek2',
}

export enum DeviceHistoryLogEvent {
  AssignToProgram = 'AssignToProgram',
  UnassignFromProgram = 'UnassignFromProgram',
  RegisterToPatient = 'RegisterToPatient',
  UnregisterFromPatient = 'UnregisterFromPatient',
  MarkAsReturned = 'MarkAsReturned',
  MarkAsLost = 'MarkAsLost',
}

export enum RelationShip {
  Father = 'Father',
  Mother = 'Mother',
  Brother = 'Brother',
  Sister = 'Sister',
  Friend = 'Friend',
  FamilyMember = 'FamilyMember',
  Other = 'Other',
  Daughter = 'Daughter',
  Son = 'Son',
  Spouse = 'Spouse',
}

export enum NoteType {
  Interaction = 'Interaction',
  General = 'General',
}

export enum PatientStatus {
  Approved = 'Approved',
  Active = 'Active',
  Discharged = 'Discharged',
}

export enum AuditLogArea {
  System = 'System',
  Programs = 'Programs',
  Inventory = 'Inventory',
  Patients = 'Patients',
  Users = 'Users',
  ActivityLogs = 'ActivityLogs',
}

export enum AuditLogEvent {
  Login = 'Login',
  Logout = 'Logout',
  Add = 'Add',
  Edit = 'Edit',
  Remove = 'Remove',
  Assign = 'Assign',
  Unassign = 'Unassign',
  Discharge = 'Discharge',
  Activate = 'Activate',
}

export enum RuleType {
  Systolic = 'Systolic',
  Diastolic = 'Diastolic',
  HeartRate = 'HeartRate',
  Weight = 'Weight',
  POSPO2 = 'POSPO2',
  POPulserate = 'POPulserate',
  BloodGlucose = 'BloodGlucose',
}

export enum RuleCondition {
  Below = 'Below',
  Above = 'Above',
  PreviousGain = 'PreviousGain',
  PreviousLoss = 'PreviousLoss',
  DailyGain = 'DailyGain',
  DailyLoss = 'DailyLoss',
  WeeklyGain = 'WeeklyGain',
  WeeklyLoss = 'WeeklyLoss',
}

export enum Icd10CodeType {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Other = 'Other',
}

export enum TableCustomizationType {
  Patients = 'Patients',
  PatientOverview = 'PatientOverview',
}

export enum TemplateType {
  Notes = 'Notes',
  Goals = 'Goals',
  Interventions = 'Interventions',
}

export enum PatientOverviewAverageType {
  Weekly = 'Weekly',
  Monthly = 'Monthly',
}

export enum NotificationLevel {
  None = 'None',
  Level1 = 'Level1',
  Level2 = 'Level2',
  Level3 = 'Level3',
}

export enum StorageFileArea {
  ProgramImage = 'ProgramImage',
  VitalsEmails = 'VitalsEmails',
  PatientDocuments = 'PatientDocuments',
}

export enum ReadingsMode {
  Historical = 'Historical',
  MailerVersion = 'MailerVersion',
}
