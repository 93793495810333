import { action } from 'easy-peasy';
import { IActionSetStatusUsers } from '../types';

const setStatusUsers: IActionSetStatusUsers = action((state, payload) => {
  const hasMore = state.pagination.itemsPerPage === payload.users.length;

  state.statusUsers.total = payload.total;
  state.statusUsers.users = payload.users;
  state.pagination.page += 1;
  state.pagination.hasMore = hasMore;
});

export default setStatusUsers;
