import { useState, ReactElement } from 'react';
import { Controller, Control, FieldErrors } from 'react-hook-form';
import get from 'lodash/get';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';

import FileUpload from '~/ui/components/common/FileUpload';

import styles from './ImageUpload.module.scss';
import { useStoreActions } from '~/store/hooks';
import { StorageFileArea } from '~/services/api/enums';
import { extractErrorMessage } from '~/utils/error';
import buildFileFormData from '~/utils/buildFileFormData';

interface IProps {
  control: Control<any>;
  errors: FieldErrors;
  name: string;
  label?: string;
  dropzoneText?: string;
  initialSrc: string;
}

const ImageUpload = ({
  control,
  errors,
  name,
  label = '',
  dropzoneText = '+ Attach image',
  initialSrc,
}: IProps): ReactElement => {
  const [uploadError, setUploadError] = useState('');
  const [src, setSrc] = useState(initialSrc);

  const { onCreateStorageFile } = useStoreActions(actions => actions.storageFiles);
  const { showError } = useStoreActions(actions => actions.snackbar);

  const error = get(errors, `${name}.message`, '');

  const onUpload = async (file: File, onChange: (value: number) => void) => {
    try {
      const storageFile = await onCreateStorageFile({
        storageFileArea: StorageFileArea.ProgramImage,
        data: buildFileFormData(file),
      });

      setSrc(storageFile.url);
      onChange(storageFile.id);
      setUploadError('');
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => (
          <FormControl component="fieldset" className={styles.uploadControl}>
            <FormLabel component="legend" className={styles.label}>
              {label}
            </FormLabel>
            <div className={styles.uploadContainer}>
              {/* Preview */}
              {value ? (
                <div className={styles.previewWrapper}>
                  <img src={src} className={styles.image} alt="Preview" />
                </div>
              ) : (
                <FileUpload
                  dropzoneText={dropzoneText}
                  onUpload={file => onUpload(file, onChange)}
                  onError={err => setUploadError(err)}
                />
              )}
            </div>
            {value && (
              <div className={styles.removeWrapper}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  className={styles.remove}
                  onClick={() => onChange(null)}
                >
                  Remove
                </Button>
              </div>
            )}
            {uploadError && <FormHelperText error>{uploadError}</FormHelperText>}
            {error && <FormHelperText error>{error}</FormHelperText>}
          </FormControl>
        )}
      />
    </>
  );
};

export default ImageUpload;
