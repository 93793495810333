import { ReactElement, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@mui/icons-material/Search';
import RotateRightIcon from '@mui/icons-material/RotateRight';

import { getInitialFilters, validate } from './form';
import Select from '~/ui/components/inputs/Select';
import Button from '~/ui/components/common/Button';

import { IUsersListFilters, IUsersSelectors } from '~/services/api/users/types';
import { allOption } from '~/ui/constants/allOptions';
import Input from '~/ui/components/inputs/Input';

import styles from './Header.module.scss';
import DatePicker from '~/ui/components/inputs/DatePicker';
import { formatToIsoWithoutTimezone, setEndOfDay } from '~/utils/date';

interface IProps {
  selectors: IUsersSelectors;
  isProgramVisible: boolean;
  onSubmit: (filters: IUsersListFilters) => void;
}

const Header = ({ selectors, isProgramVisible, onSubmit }: IProps): ReactElement => {
  const initialFilters = getInitialFilters();

  const formMethods = useForm<IUsersListFilters>({
    resolver: validate,
    defaultValues: initialFilters,
  });

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = formMethods;

  const programOptions = useMemo(
    () => [
      allOption,
      ...selectors.programs.map(item => ({
        value: item.id,
        label: item.name,
      })),
    ],
    [selectors.programs],
  );

  const onSubmitForm = (values: IUsersListFilters) => {
    onSubmit({
      ...values,
      startDate: formatToIsoWithoutTimezone(values.startDate),
      endDate: formatToIsoWithoutTimezone(setEndOfDay(values.endDate)),
    });
  };

  return (
    <FormProvider {...formMethods}>
      <form className={styles.container} onSubmit={handleSubmit(onSubmitForm)}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Input name="name" register={register} errors={errors} label="Name" />
          </Grid>
          <Grid item xs={2}>
            <DatePicker
              placeholder=".. /.. /.."
              label="Login Start Date"
              name="startDate"
              maxDate={new Date()}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item xs={2}>
            <DatePicker
              placeholder=".. /.. /.."
              label="Login End Date"
              name="endDate"
              maxDate={new Date()}
              control={control}
              errors={errors}
            />
          </Grid>
          {isProgramVisible && (
            <Grid item xs={2}>
              <Select
                name="programId"
                control={control}
                errors={errors}
                options={programOptions}
                label="Program"
              />
            </Grid>
          )}
          <Grid item xs />
          <Grid item>
            <Button variant="outlined" type="submit" color="primary" startIcon={<SearchIcon />}>
              Search
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              type="button"
              startIcon={<RotateRightIcon />}
              onClick={() => {
                reset(initialFilters);
                onSubmit(initialFilters);
              }}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default Header;
