import axios from 'axios';
import { IResponse } from '~/services/api/types';
import {
  IStorageFile,
  IStorageFileCreate,
  IStorageFilesListFilters,
  IStorageFilesSelectors,
} from './types';
import { StorageFileArea } from '../enums';

const apiUrl = 'storage-files';

export const getPreSignedUrl = (id: number, forceDownload: boolean): IResponse<string> =>
  axios({
    method: 'get',
    url: `${apiUrl}/${id}`,
    params: {
      forceDownload,
      disableRedirect: true,
    },
  });

export const getList = (params: IStorageFilesListFilters): IResponse<IStorageFile[]> =>
  axios({
    method: 'get',
    url: apiUrl,
    params,
  });

export const getSelectorsByArea = (area: StorageFileArea): IResponse<IStorageFilesSelectors> =>
  axios({
    method: 'get',
    url: `${apiUrl}/selectors/by-area/${area}`,
  });

export const create = (
  storageFileArea: StorageFileArea,
  data: FormData,
  _abortController: AbortController,
  onUploadProgress: (event: ProgressEvent) => void,
): IResponse<IStorageFile> =>
  axios({
    method: 'post',
    url: apiUrl,
    data,
    params: {
      storageFileArea,
    },
    onUploadProgress,
    // TODO: signal: abortController.signal,
  });

export const createByPatientId = (
  patientId: number,
  data: IStorageFileCreate[],
): IResponse<IStorageFile[]> =>
  axios({
    method: 'post',
    url: `${apiUrl}/by-patient-id/${patientId}`,
    data,
  });

export const remove = (id: number): IResponse<void> =>
  axios({
    method: 'delete',
    url: `${apiUrl}/${id}`,
  });

export default {};
